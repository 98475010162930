import React from "react";
import ReactDOM from "react-dom/client";
import { onlineManager } from "@tanstack/react-query";
import { theme } from "./theme";
import { AppConfigurationProvider, bool, defaultLocalizationStorageImpl, Fonts, localizationService } from "am-tax-fe-core";
import { ChakraProvider } from "@chakra-ui/react";
import { Middleware } from "openapi-typescript-fetch";
import { configure } from "./api";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { UnauthenticatedLandingPage } from "./pages/UnauthenticatedLandingPage";
import { PageRoutes } from "./pages";
//import { gdprConsentStorageService } from "./gdpr/GdprConsentStorageService.ts";

//import the fe-core style sheet (fe-core bundles up all the stylesheets that are included from third party libs and exports them as a single css file)
import "am-tax-fe-core/index.css";

function configureHttpClient(baseUrl: string, getAccessToken: () => Promise<string>) {
    const msalMiddleware: Middleware = async (url, init, next) => {
        if (!bool(import.meta.env.VITE_BYPASS_AUTH)) {
            const accessToken = await getAccessToken();
            init.headers.append("Authorization", `Bearer ${accessToken}`);
        }
        return next(url, init);
    };

    configure(baseUrl, [msalMiddleware]);
}

(async function () {
    if (bool(import.meta.env.VITE_BYPASS_AUTH) || bool(import.meta.env.VITE_MOCK_PARTIAL_BACKEND) || bool(import.meta.env.VITE_MOCK_ENTIRE_BACKEND)) {
        // this probably seems backwards, but but we want to tell react query to act like it is online even if it is offline
        onlineManager.setOnline(true);

        // @ts-ignore
        const setup = await import("./api/msw/setupForBrowser.ts");
        const { worker } = setup;
        await worker.start();
    }

    // we need to initialize the localization service before we render the app
    await localizationService.init(defaultLocalizationStorageImpl);

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    root.render(
        <React.StrictMode>
            <ChakraProvider theme={theme} resetCSS>
                <Fonts />
                <AppConfigurationProvider
                    envJsonUrl={"/env.json"}
                    byPassAuth={bool(import.meta.env.VITE_BYPASS_AUTH) || bool(import.meta.env.VITE_MOCK_ENTIRE_BACKEND)}
                    unauthenticatedLandingPage={<UnauthenticatedLandingPage />}
                    onApiContextInit={configureHttpClient}
                    enableAppInsights={bool(import.meta.env.VITE_ENABLE_APP_INSIGHTS)}
                    version={{
                        ver: import.meta.env.VITE_BUILD_COMMIT,
                        build: import.meta.env.VITE_BUILD_NUMBER,
                    }}
                    apiErrorHandlerOptions={{
                        preferFallbackMessage: true,
                    }}
                    //  gdprConsentStorageService={gdprConsentStorageService}
                >
                    <PageRoutes />
                    {bool(import.meta.env.VITE_ENABLE_REACT_QUERY_DEV_TOOLS) && <ReactQueryDevtools initialIsOpen={false} />}
                </AppConfigurationProvider>
            </ChakraProvider>
        </React.StrictMode>,
    );
})();
