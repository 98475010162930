import { FC, FunctionComponent, PropsWithChildren, ReactNode, useMemo } from "react";
import useState from "react-usestateref";
import { Badge, Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select, Spacer, Text, Textarea, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
    DeleteEngagementRequestArgs,
    DeleteProjectRequestArgs,
    Document,
    downloadEngagementDocument,
    Engagement,
    Participant,
    PostEngagementRequestArgs,
    PostProjectRequestArgs,
    Project,
    PutEngagementRequestArgs,
    PutProjectRequestArgs,
    Request,
    UploadDocumentsToEngagementRequestArgs,
    UploadDocumentsToProjectRequestArgs,
    User,
    useRequestStatuses,
} from "../../api";

import { SubmitHandler, useForm } from "react-hook-form";

import {
    DataTable,
    DatePicker,
    DownloadButton,
    dropTimeFromIsoFormat,
    FileContainer,
    FileUploader,
    formatFileSize,
    formatTimestampStr,
    RepeatShimmer,
    useConfirm,
    useToast,
} from "am-tax-fe-core";
import { createColumnHelper } from "@tanstack/react-table";
import { AreaPermissions, canDelete, PermissionsByAreaType, RequestArea, SecurityArea } from "../../auth";
import { UnauthorizedPage } from "../UnauthorizedPage";
import { IconFileDownload } from "@tabler/icons-react";
import { UseQueryResult } from "@tanstack/react-query";
import { RequestStatusEnum } from "../../util/RequestStatusEnum.ts";
import { Mutation } from "../../util/queryUtils.ts";

// a list of years going seven years back and two yeas forward
const startingYear = new Date().getFullYear() + 2;
const years = Array.from({ length: 10 }, (_, i) => startingYear - i);

export type ProjectEngagement = Project | Engagement;
export type SaveRequestArgs = PostEngagementRequestArgs | PutEngagementRequestArgs | PostProjectRequestArgs | PutProjectRequestArgs;
export type DeleteRequestArgs = DeleteEngagementRequestArgs | DeleteProjectRequestArgs;
export type UploadDocumentsToRequestArgs = UploadDocumentsToEngagementRequestArgs | UploadDocumentsToProjectRequestArgs;

export interface RequestFormProps {
    navigateBack?: () => void;
    areaPermissions: AreaPermissions<SecurityArea>;
    projectEngagementQuery: UseQueryResult<ProjectEngagement>;
    requestQuery: UseQueryResult<Request>;
    participantsQuery: UseQueryResult<Participant[]>;
    saveRequestMutation: Mutation<SaveRequestArgs, Request>;
    deleteRequestMutation: Mutation<DeleteRequestArgs>;
    uploadFileToRequestMutation: Mutation<UploadDocumentsToRequestArgs>;
    onSaveComplete: (projectOrEngagement: ProjectEngagement, request: Request, filesUploaded?: boolean) => void;
}
type ReadOnlyRenderProps = {
    isReadOnly: boolean;
    readOnlyValue: ReactNode;
};
const ReadOnlyRender: FC<PropsWithChildren<ReadOnlyRenderProps>> = ({ isReadOnly, readOnlyValue, children }) => {
    return isReadOnly ? readOnlyValue : children;
};

function canCreateOrUpdateOrReassign<A extends SecurityArea>(
    areaPermissions: AreaPermissions<A>,
    createPermission: PermissionsByAreaType<A>,
    updatePermission: PermissionsByAreaType<A>,
    reassignedPermission: PermissionsByAreaType<A>,
    entityId: string | undefined,
): boolean {
    if (entityId) {
        return areaPermissions.hasAny(updatePermission, reassignedPermission);
    } else {
        return areaPermissions.has(createPermission);
    }
}
function accessMatchesIntent<A extends SecurityArea>(
    areaPermissions: AreaPermissions<A>,
    createPermission: PermissionsByAreaType<A> | null,
    readPermission: PermissionsByAreaType<A> | null,
    updatePermission: PermissionsByAreaType<A> | null,
    deletePermission: PermissionsByAreaType<A> | null,
    reassignedPermission: PermissionsByAreaType<A> | null,
    entityId: string | undefined,
): boolean {
    if (entityId) {
        return (
            areaPermissions.has(readPermission) ||
            areaPermissions.has(updatePermission) ||
            areaPermissions.has(deletePermission) ||
            areaPermissions.has(reassignedPermission)
        );
    } else {
        return areaPermissions.has(createPermission);
    }
}

function readonlyAccess<A extends SecurityArea>(
    areaPermissions: AreaPermissions<A>,
    updatePermission: PermissionsByAreaType<A> | null,
    reassignedPermission: PermissionsByAreaType<A> | null,
    readPermission: PermissionsByAreaType<A> | null,
    entityId: string | undefined,
): boolean {
    if (entityId) {
        return !areaPermissions.has(updatePermission) && (areaPermissions.has(reassignedPermission) || areaPermissions.has(readPermission));
    } else {
        return false;
    }
}

export const RequestForm: FunctionComponent<RequestFormProps> = ({
    navigateBack,
    areaPermissions,
    projectEngagementQuery,
    requestQuery,
    participantsQuery,
    saveRequestMutation,
    deleteRequestMutation,
    uploadFileToRequestMutation,
    onSaveComplete,
}) => {
    const toast = useToast();
    const { engagementId, requestId, dueDate } = useParams();

    const requestStatusesQuery = useRequestStatuses();

    const projectOrEngagement = projectEngagementQuery.data;

    const request = requestQuery.data;
    //@ts-expect-error - newRequestJustSaved can't be left off even though it isn't used.
    const [newRequestJustSaved, setNewRequestJustSaved, newRequestJustSavedRef] = useState<Request | undefined>(undefined);

    const users: User[] = useMemo(() => {
        const participantList = participantsQuery.data;
        const users = participantList?.map(p => p.user) || [];
        if (request?.assignedTo && !participantList?.find(p => p.user.id === request.assignedTo?.id)) {
            users.push(request.assignedTo);
        }
        return users;
    }, [request, participantsQuery.data]);

    // ---- Code to Load the Form ----
    const formValues: Partial<SaveRequestArgs> = {
        title: request?.title,
        description: request?.description,
        dueDate: dropTimeFromIsoFormat(request?.dueDate) || dueDate || "",
        statusId: request?.status?.id || RequestStatusEnum.Requested,
        year: request?.year,
        assignedToId: request?.assignedTo?.id,
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Partial<SaveRequestArgs>>({
        values: formValues,
    });

    // ---- Setup Submit Handlers for saving the Form ----
    const [uploading, setUploading] = useState(false);
    const onSubmit: SubmitHandler<Partial<SaveRequestArgs>> = async data => {
        const args = saveRequestMutation.updateArgs({
            requestId: request?.requestId ?? "",
            engagementId: engagementId ?? "",
            title: data.title!,
            dueDate: data.dueDate!,
            description: data.description,
            statusId: data.statusId!,
            year: data.year,
            assignedToId: data.assignedToId,
        });

        const response = await saveRequestMutation.query.mutateAsync(args);
        if (!request) {
            setNewRequestJustSaved(response);
        }

        const requestId = (response as Request).requestId;

        if (fileQueue.length > 0) {
            setUploading(true);
            for (const fileContainer of fileQueue) {
                const args = uploadFileToRequestMutation.updateArgs({ engagementId: engagementId ?? "", requestId, fileContainer });
                uploadFileToRequestMutation.query.mutate(args);
            }
        } else {
            onSaveComplete(projectOrEngagement!, response as Request);
            toast({
                title: "Saved",
                description: "Request Saved.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            goBackToAllRequests();
        }
    };

    // ---- Setup the Delete Handler ----
    const { confirm, ConfirmDialog } = useConfirm({ title: "Delete Request?", prompt: "Are you sure you want to delete this request?" });
    const doDelete = async () => {
        const result = await confirm();
        if (result) {
            const args = deleteRequestMutation.updateArgs({ requestId: requestId ?? "", engagementId: engagementId ?? "" });
            deleteRequestMutation.query.mutate(args, {
                onSuccess: () => {
                    toast({
                        title: "Deleted",
                        description: "Request Deleted.",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    goBackToAllRequests();
                },
            });
        }
    };

    // ---- Setup File Upload mockApiResponses ----
    const [fileQueue, setFileQueue, fileQueueRef] = useState<FileContainer[]>([]);

    const onFileAdded = async (fileContainer: FileContainer) => {
        fileContainer.metaData = { folderId: projectOrEngagement?.clientDocumentsRootFolderId ?? "" };
        if (requestId) {
            // if we've already saved the request, upload the file immediately
            const args = uploadFileToRequestMutation.updateArgs({ engagementId: engagementId ?? "", requestId: requestId ?? "", fileContainer });
            uploadFileToRequestMutation.query.mutate(args);
        } else {
            // otherwise queue the file for upload when the request is saved
            const queue = [...fileQueueRef.current];
            queue.push(fileContainer);
            setFileQueue(queue);
        }
    };
    const onFileCancelled = async (fileContainer: FileContainer) => {
        const updatedQueue = [];
        const queue = [...fileQueueRef.current];
        for (const file of queue) {
            if (file.index !== fileContainer.index) {
                updatedQueue.push(file);
            }
        }
        setFileQueue(updatedQueue);
    };

    const onBatchUploaded = async () => {
        try {
            const currentRequest = request || newRequestJustSavedRef.current;
            onSaveComplete(projectOrEngagement!, currentRequest!, true);
            setFileQueue([]);
            toast({
                title: "Saved",
                description: "Saved Request and Uploaded Files.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            if (newRequestJustSavedRef.current) {
                setNewRequestJustSaved(undefined);
                goBackToAllRequests();
            }
        } finally {
            setUploading(false);
        }
    };

    const goBackToAllRequests = () => {
        if (navigateBack) {
            navigateBack();
        } else {
            window.history.back();
        }
    };

    if (!accessMatchesIntent(areaPermissions, RequestArea.create, RequestArea.read, RequestArea.update, RequestArea.delete, RequestArea.reassign, requestId)) {
        return <UnauthorizedPage />;
    }

    const columnHelper = createColumnHelper<Document>();

    const isReadOnly: boolean = readonlyAccess(areaPermissions, RequestArea.update, RequestArea.reassign, RequestArea.read, requestId);

    return (
        <>
            <VStack hidden={!requestQuery.isLoading && !requestStatusesQuery.isLoading} spacing="1px" alignItems="stretch">
                <RepeatShimmer times={5} height="40px" />
            </VStack>

            {!requestQuery.isLoading && !requestStatusesQuery.isLoading && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset disabled={saveRequestMutation.query.isPending || uploading}>
                        <VStack gap={"1rem"}>
                            <Flex gap="2rem" width={"100%"} alignItems={"stretch"}>
                                <VStack spacing="1rem" alignItems="stretch" flexBasis={"50%"}>
                                    {areaPermissions.hasAny(RequestArea.update, RequestArea.create, RequestArea.reassign, RequestArea.read) && (
                                        <>
                                            <FormControl isInvalid={!!errors?.title}>
                                                <FormLabel>Title:</FormLabel>
                                                <ReadOnlyRender isReadOnly={isReadOnly} readOnlyValue={<Text>{request?.title}</Text>}>
                                                    <Input {...register("title", { required: "Title is required." })} />
                                                </ReadOnlyRender>

                                                <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl isInvalid={!!errors?.description}>
                                                <FormLabel>Description:</FormLabel>
                                                <ReadOnlyRender isReadOnly={isReadOnly} readOnlyValue={<Text>{request?.description}</Text>}>
                                                    <Textarea {...register("description")} />
                                                </ReadOnlyRender>
                                                <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                                            </FormControl>
                                        </>
                                    )}
                                    {areaPermissions.hasAny(RequestArea.update, RequestArea.reassign, RequestArea.create) ? (
                                        <FormControl isInvalid={!!errors?.assignedToId}>
                                            <FormLabel>Assigned To:</FormLabel>
                                            <Select {...register("assignedToId")} placeholder={"None"}>
                                                {users?.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.firstName} {user.lastName}
                                                    </option>
                                                ))}
                                            </Select>
                                            <FormErrorMessage>{errors?.assignedToId?.message}</FormErrorMessage>
                                        </FormControl>
                                    ) : (
                                        areaPermissions.has(RequestArea.read) && (
                                            <FormControl isInvalid={!!errors?.assignedToId}>
                                                <FormLabel>Assigned To:</FormLabel>
                                                <Text>
                                                    {request?.assignedTo?.firstName} {request?.assignedTo?.lastName}
                                                </Text>
                                                <FormErrorMessage>{errors?.assignedToId?.message}</FormErrorMessage>
                                            </FormControl>
                                        )
                                    )}
                                </VStack>
                                <VStack spacing="1rem" alignItems="stretch" flexBasis={"50%"}>
                                    {areaPermissions.hasAny(RequestArea.update, RequestArea.create, RequestArea.reassign, RequestArea.read) && (
                                        <>
                                            <FormControl isInvalid={!!errors?.dueDate}>
                                                <FormLabel>Due:</FormLabel>
                                                <ReadOnlyRender
                                                    isReadOnly={isReadOnly}
                                                    readOnlyValue={<Text>{dropTimeFromIsoFormat(request?.dueDate) || dueDate || undefined}</Text>}
                                                >
                                                    <DatePicker {...register("dueDate", { required: "Due Date is required." })} />
                                                </ReadOnlyRender>
                                                <FormErrorMessage>{errors?.dueDate?.message}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl isInvalid={!!errors?.statusId}>
                                                <FormLabel>Status:</FormLabel>
                                                <ReadOnlyRender
                                                    isReadOnly={isReadOnly}
                                                    readOnlyValue={<Text>{request?.status?.name || RequestStatusEnum.Requested}</Text>}
                                                >
                                                    <Select placeholder={"Select a Status"} {...register("statusId", { required: "Status is required." })}>
                                                        {requestStatusesQuery.data?.map(status => (
                                                            <option key={status.id} value={status.id}>
                                                                {status.name}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </ReadOnlyRender>
                                                <FormErrorMessage>{errors?.statusId?.message}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl isInvalid={!!errors?.year}>
                                                <FormLabel>Year:</FormLabel>
                                                <ReadOnlyRender isReadOnly={isReadOnly} readOnlyValue={<Text>{request?.year ? request.year : undefined}</Text>}>
                                                    <Select placeholder={"Select Year"} {...register("year")}>
                                                        {years.map(year => (
                                                            <option key={year} value={year}>
                                                                {year}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </ReadOnlyRender>
                                                <FormErrorMessage>{errors?.year?.message}</FormErrorMessage>
                                            </FormControl>
                                        </>
                                    )}
                                </VStack>
                            </Flex>
                            {request?.documents?.length && (
                                <DataTable
                                    tableProps={{ size: "sm" }}
                                    data={request.documents}
                                    columns={[
                                        columnHelper.accessor("name", {
                                            header: "File Name",
                                            cell: ({ row: { original: document } }) => (
                                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Box>{document.name}</Box>
                                                    <Box>{document.versionNumber > 1 && <Badge colorScheme={"orange"}>V{document.versionNumber}</Badge>}</Box>
                                                </Box>
                                            ),
                                        }),
                                        columnHelper.accessor("uploadedDate", {
                                            header: "Uploaded",
                                            cell: ({ getValue }) => formatTimestampStr(getValue()),
                                        }),
                                        columnHelper.accessor("uploadedByName", { header: "Uploaded By" }),
                                        columnHelper.accessor("size", {
                                            header: "Size",
                                            cell: props => {
                                                return formatFileSize(props.getValue());
                                            },
                                        }),
                                        columnHelper.accessor("id", {
                                            header: "",
                                            enableColumnFilter: false,
                                            enableSorting: false,
                                            cell: ({ row: { original: document } }) => (
                                                <DownloadButton
                                                    icon={<IconFileDownload />}
                                                    variant={"naked"}
                                                    bytesExpectedOverride={document.size}
                                                    downloadFn={async progressCallback => {
                                                        try {
                                                            const blob = await downloadEngagementDocument({
                                                                engagementId: engagementId ?? "",
                                                                documentId: document.id,
                                                                documentVersionId: document.versionId,
                                                                progressCallback,
                                                            });
                                                            return { blob, fileName: document.name };
                                                        } catch (ex) {
                                                            toast({
                                                                title: "Failure",
                                                                description: "Document couldn't be downloaded",
                                                                status: "error",
                                                                duration: 6000,
                                                                isClosable: true,
                                                            });
                                                            throw ex;
                                                        }
                                                    }}
                                                />
                                            ),
                                        }),
                                    ]}
                                />
                            )}
                            {canCreateOrUpdateOrReassign(areaPermissions, RequestArea.create, RequestArea.update, RequestArea.reassign, requestId) && (
                                <FileUploader
                                    flattenFolders={true}
                                    onFileAdded={onFileAdded}
                                    onFileCancelled={onFileCancelled}
                                    onBatchUploaded={onBatchUploaded}
                                />
                            )}
                            <Flex justifyContent="flex-end" mt={"2rem"} width={"full"}>
                                {canDelete(areaPermissions, RequestArea.delete, requestId) && (
                                    <Button
                                        variant="ghost"
                                        onClick={doDelete}
                                        colorScheme={"red"}
                                        fontWeight={200}
                                        isDisabled={uploading || saveRequestMutation.query.isPending}
                                        isLoading={deleteRequestMutation.query.isPending}
                                        loadingText={"Deleting"}
                                    >
                                        Delete Request
                                    </Button>
                                )}
                                <Spacer />
                                {canCreateOrUpdateOrReassign(areaPermissions, RequestArea.create, RequestArea.update, RequestArea.reassign, requestId) && (
                                    <Button
                                        type="submit"
                                        variant={"primary"}
                                        mr={3}
                                        isLoading={uploading || saveRequestMutation.query.isPending}
                                        isDisabled={saveRequestMutation.query.isPending}
                                        loadingText={"Saving"}
                                    >
                                        Save
                                    </Button>
                                )}
                                <Button variant="ghost" onClick={goBackToAllRequests}>
                                    Cancel
                                </Button>
                            </Flex>
                        </VStack>
                    </fieldset>
                </form>
            )}

            <ConfirmDialog />
        </>
    );
};
