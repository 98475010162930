import { FunctionComponent, useState } from "react";
import { Button, Card, CardBody, CardHeader, Flex, Icon } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { RequestForm } from "../EngagementRequestPage/RequestForm.tsx";
import { Page } from "../Page.tsx";
import { useRequestFormForProject } from "./useRequestFormForProject.ts";
import ConversationDrawer from "../../components/ConversationDrawer";
import { IconMessages } from "@tabler/icons-react";
import { PLACEHOLDER_FOR_ID_NOT_CREATED } from "../../constants/index.ts";

export const ProjectRequestPage: FunctionComponent = () => {
    const { engagementId, projectId, requestId } = useParams();
    const [isOpen, setIsOpen] = useState(false);

    const requestFormProps = useRequestFormForProject(engagementId ?? "", projectId ?? "", requestId ?? "");

    const hasValidRequestId = !!requestId && requestId.toLowerCase() !== PLACEHOLDER_FOR_ID_NOT_CREATED;

    return (
        <Page title={`${requestFormProps.requestQuery.data?.requestId ? "Edit" : "Add"} Request`}>
            <Card>
                <CardHeader bgGradient="linear(to-br, purple.500, purple.300)"></CardHeader>
                <CardBody>
                    {hasValidRequestId && (
                        <Flex justify="flex-end" mb=".5rem">
                            <Button rightIcon={<Icon as={IconMessages} />} textAlign="right" variant="outline" onClick={() => setIsOpen(!isOpen)}>
                                Conversations
                            </Button>
                        </Flex>
                    )}
                    <RequestForm {...requestFormProps} />
                </CardBody>
            </Card>
            <ConversationDrawer engagementId={engagementId ?? ""} projectId={projectId} requestId={requestId ?? ""} show={isOpen} toggleShow={setIsOpen} />
        </Page>
    );
};
