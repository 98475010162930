import { Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ApplicationLayout as CoreApplicationLayout, ErrorBoundary, TrackNavigation, PageLoading } from "am-tax-fe-core";
import { LeftNav } from "./LeftNav";
import { ScrollToTop } from "../components/ScrollToTop";
import { Page } from "./Page.tsx";

function ApplicationLayout() {
    const location = useLocation();

    return (
        <>
            <ErrorBoundary location={location}>
                <CoreApplicationLayout appName={import.meta.env.VITE_APP_NAME} leftNav={<LeftNav />}>
                    <Suspense
                        fallback={
                            <Page title={""}>
                                <PageLoading text={"Loading Components..."} />
                            </Page>
                        }
                    >
                        <Outlet />
                    </Suspense>
                </CoreApplicationLayout>
            </ErrorBoundary>
            <ScrollToTop />
            <TrackNavigation location={location} />
        </>
    );
}

export default ApplicationLayout;
