import { FC, useRef } from "react";
import { Button, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Spacer, Switch, Textarea, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
    DeleteEngagementEventArgs,
    DeleteProjectEventArgs,
    Event,
    PostEngagementEventArgs,
    PostProjectEventArgs,
    PutEngagementEventArgs,
    PutProjectEventArgs,
    useCurrentUser,
    useStates,
} from "../../api";
import { SubmitHandler, useForm } from "react-hook-form";
import { DatePicker, dropTimeFromIsoFormat, MultiSelectControlled, RepeatShimmer, useConfirm, useToast } from "am-tax-fe-core";
import { accessMatchesIntent, AreaPermissions, CalendarArea, canCreateOrUpdate, canDelete, SecurityArea } from "../../auth";
import { UnauthorizedPage } from "../UnauthorizedPage";
import { UseQueryResult } from "@tanstack/react-query";
import { Mutation } from "../../util/queryUtils.ts";

export type SaveEventArgs = PostEngagementEventArgs | PutEngagementEventArgs | PostProjectEventArgs | PutProjectEventArgs;
export type DeleteEventArgs = DeleteEngagementEventArgs | DeleteProjectEventArgs;

export interface EventFormProps {
    navigateBack?: () => void;
    areaPermissions: AreaPermissions<SecurityArea>;
    eventQuery: UseQueryResult<Event>;
    saveEventMutation: Mutation<SaveEventArgs>;
    deleteEventMutation: Mutation<DeleteEventArgs>;
    setDeleting: (deleting: boolean) => void;
}

export const EventForm: FC<EventFormProps> = ({ navigateBack, areaPermissions, eventQuery, saveEventMutation, deleteEventMutation, setDeleting }) => {
    const toast = useToast();
    const { engagementId, eventId, startDate } = useParams();
    const event = eventQuery.data;
    const statesQuery = useStates();
        
    const currentUserQuery = useCurrentUser();
    const currentUser = currentUserQuery.data;

    // ---- Code to Load the Form ----
    const formValues: Partial<SaveEventArgs> = {
        title: event?.title,
        startDate: dropTimeFromIsoFormat(event?.startDate) || startDate,
        description: event?.description,
        isClientVisible: event?.isClientVisible,
        entity: event?.entity,
        return: event?.return,
        yearEnd: dropTimeFromIsoFormat(event?.yearEnd),
        stateIds: event?.states?.map(state => state.id),
        form: event?.form,
    };
    const formRef = useRef<HTMLFormElement>(null);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<Partial<SaveEventArgs>>({
        values: formValues,
    });

    // ---- Setup Submit Handlers for saving the Form ----
    const onSubmit: SubmitHandler<Partial<SaveEventArgs>> = async data => {
        if (event?.eventId) {
            (data as PutEngagementEventArgs).eventId = event.eventId;
        }
        data.engagementId = engagementId;
        const args = saveEventMutation.updateArgs(data as SaveEventArgs);
        saveEventMutation.query.mutate(args, {
            onSuccess: () => {
                toast({
                    title: "Saved",
                    description: "Event Saved.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                goBackToAllEvents();
            },
        });
    };
    const formSubmit = handleSubmit(onSubmit);

    // ---- Setup the Delete Handler ----
    const { confirm, ConfirmDialog } = useConfirm({ title: "Delete Event?", prompt: "Are you sure you want to delete this event?" });
    const doDelete = async () => {
        const result = await confirm();
        if (result) {
            setDeleting(true);
            const args = deleteEventMutation.updateArgs({ eventId: eventId ?? "", engagementId: engagementId ?? "" });
            deleteEventMutation.query.mutate(args, {
                onSuccess: () => {
                    toast({
                        title: "Deleted",
                        description: "Event Deleted.",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    goBackToAllEvents();
                },
                onError: () => {
                    setDeleting(false);
                },
            });
        }
    };

    // ---- Navigation Helper ----
    const goBackToAllEvents = () => {
        if (navigateBack) {
            navigateBack();
        } else {
            window.history.back();
        }
    };

    if (!accessMatchesIntent(areaPermissions, CalendarArea.create, CalendarArea.read, CalendarArea.update, CalendarArea.delete, eventId)) {
        return <UnauthorizedPage />;
    }

    return (
        <>
            <VStack hidden={!eventQuery.isLoading && !statesQuery.isLoading} spacing="1px" alignItems="stretch">
                <RepeatShimmer times={5} height="40px" />
            </VStack>

            {!eventQuery.isLoading && !statesQuery.isLoading && (
                <form ref={formRef} onSubmit={formSubmit}>
                    <fieldset disabled={saveEventMutation.query.isPending}>
                        <VStack spacing={"1rem"}>
                            <Flex gap="2rem" width={"100%"}>
                                <FormControl isInvalid={!!errors?.title} flexBasis={"50%"}>
                                    <FormLabel>Title:</FormLabel>
                                    <Input {...register("title", { required: "Title is required." })} />
                                    <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors?.startDate} flexBasis={"50%"}>
                                    <Flex>
                                        <FormLabel>Date:</FormLabel>
                                    </Flex>
                                    <DatePicker placeholder={"mm/dd/yyyy"} {...register("startDate", { required: "Date is required." })} />
                                    <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex gap="2rem" width={"100%"}>
                                <FormControl isInvalid={!!errors?.description}>
                                    <Flex>
                                        <FormLabel>Description:</FormLabel>
                                    </Flex>
                                    <Textarea {...register("description")} />
                                    <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                                </FormControl>
                                
                                <FormControl hidden={!currentUser?.isInternal} isInvalid={!!errors?.isClientVisible } display={"flex"} alignItems={"center"} gap={"1rem"}>
                                    <FormLabel m={0}>Visible to Client</FormLabel>
                                    <Switch {...register("isClientVisible")} size={"lg"} colorScheme={"green"} {...register("isClientVisible")} />
                                    <FormErrorMessage>{errors?.isClientVisible?.message}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Divider border={"none"} />
                            <Heading variant={"info"} alignSelf={"flex-start"}>
                                Additional Info
                            </Heading>
                            <Flex gap="2rem" width={"100%"}>
                                <VStack spacing="1rem" flexBasis={"50%"}>
                                    <FormControl isInvalid={!!errors?.entity}>
                                        <FormLabel>Entity:</FormLabel>
                                        <Input {...register("entity")} />
                                        <FormErrorMessage>{errors?.entity?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.return}>
                                        <FormLabel>Return:</FormLabel>
                                        <Input {...register("return")} />
                                        <FormErrorMessage>{errors?.return?.message}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                                <VStack spacing="1rem" flexBasis={"50%"}>
                                    <FormControl isInvalid={!!errors?.yearEnd}>
                                        <FormLabel>Year End:</FormLabel>
                                        <DatePicker placeholder={"mm/dd/yyyy"} {...register("yearEnd")} />
                                        <FormErrorMessage>{errors?.yearEnd?.message}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors?.stateIds}>
                                        <FormLabel>States:</FormLabel>
                                        <MultiSelectControlled
                                            control={control}
                                            name={"stateIds"}
                                            placeholder={"Select States..."}
                                            options={statesQuery.data?.map(state => ({
                                                value: state.id,
                                                label: state.name,
                                            }))}
                                        />
                                        <FormErrorMessage>{errors?.stateIds?.message}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                            </Flex>
                            <FormControl isInvalid={!!errors?.form}>
                                <FormLabel>Forms:</FormLabel>
                                <Input {...register("form")} />
                                <FormErrorMessage>{errors?.form?.message}</FormErrorMessage>
                            </FormControl>

                            <Flex justifyContent="flex-end" mt={"2rem"} width={"full"} gap={"1rem"}>
                                {canDelete(areaPermissions, CalendarArea.delete, eventId) && (
                                    <Button
                                        variant="ghost"
                                        onClick={doDelete}
                                        colorScheme={"red"}
                                        fontWeight={200}
                                        isLoading={deleteEventMutation.query.isPending}
                                        loadingText={"Deleting"}
                                    >
                                        Delete Event
                                    </Button>
                                )}
                                <Spacer />
                                {canCreateOrUpdate(areaPermissions, CalendarArea.create, CalendarArea.update, eventId) && (
                                    <Button type="submit" variant={"primary"} isLoading={saveEventMutation.query.isPending} loadingText={"Saving"}>
                                        Save
                                    </Button>
                                )}
                                <Button variant="ghost" onClick={goBackToAllEvents}>
                                    Cancel
                                </Button>
                            </Flex>
                        </VStack>
                    </fieldset>
                </form>
            )}
            <ConfirmDialog />
        </>
    );
};
