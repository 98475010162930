import { FunctionComponent, RefObject, useState, useRef } from "react";

import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Flex,
    Box,
    Tooltip,
    Button,
} from "@chakra-ui/react";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useToast } from "am-tax-fe-core";

import { Participant, useCurrentUser } from "../../api";
import { ConversationRequest, User, CreateRequestConversationArgs } from "../../api";
import { useCreateRequestConveration, useEngagementUsers, useProjectUsers } from "../../api/apiQueries";
import RichTextEditor from "./rich-text/RichTextEditor";

interface Props {
    requestId: string;
    engagementId: string;
    projectId?: string;
    focusAfterClosingRef?: RefObject<HTMLElement>;
    show: boolean;
    toggleShow: (show: boolean) => void;
}

type Inputs = {
    title: string;
    content: string;
};

const CreateConversationDialog: FunctionComponent<Props> = (props: Props) => {
    const toast = useToast();
    const initialTitleRef = useRef<HTMLInputElement | null>(null);
    const currentUserQuery = useCurrentUser();
    const currentUser = currentUserQuery.data as User;

    const projectUsersQuery = useProjectUsers(props.engagementId, props.projectId ?? "");
    const engagementUsersQuery = useEngagementUsers(props.engagementId, []);
    const participants: Participant[] | undefined = props.projectId ? projectUsersQuery.data : engagementUsersQuery.data;

    const [publish, setPublish] = useState<boolean>(true);
    const [richContent, setRichContent] = useState<{ html: string; text: string }>({ html: "", text: "" });

    const {
        register,
        handleSubmit,
        formState: { errors },
        resetField,
        setValue,
    } = useForm<Inputs>();

    const { requestId, engagementId, projectId } = props;
    const createConversationQuery = useCreateRequestConveration();
    const createConversation = async (conversation: ConversationRequest) => {
        const args: CreateRequestConversationArgs = {
            requestId,
            ...conversation,
            engagementId,
            projectId,
        };
        await createConversationQuery.mutateAsync(args);
        toast({
            title: "Created",
            description: "Conversation created.",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
        props.toggleShow(false);
        resetField("title");
        resetField("content");
    };

    // dev-note: deconstructing register to get hold of internal ref
    const { ref: titleRef, ...titleRest } = register("title", {
        required: "Please fill in a conversation title.",
    });
    const contentReg = register("content", {
        required: "Please fill in comment.",
    });

    const handleClose = () => {
        props.toggleShow(false);
        resetField("title");
        resetField("content");
    };

    const handleTogglePublish = () => setPublish(!publish);

    const onSubmit: SubmitHandler<Inputs> = data => {
        const conversation: ConversationRequest = {
            requestId: props.requestId,
            title: data.title,
            user: currentUser,
            isPublished: publish,
            comments: [
                {
                    id: undefined!, //for convience sake
                    conversationId: undefined!, //for convience sake
                    content: richContent.html,
                    contentText: richContent.text,
                    user: currentUser,
                    isPublished: publish,
                },
            ],
        };
        createConversation(conversation);
        handleClose();
    };

    const onContentChanged = (html: string, text: string) => {
        setRichContent({ html, text });
        setValue("content", text, {
            shouldValidate: true,
            shouldTouch: true,
        });
    };

    let placeholder = "Write a comment";
    if (participants && participants.length > 0) {
        placeholder += " (use @ to mention)";
    }

    return (
        <Modal initialFocusRef={initialTitleRef} finalFocusRef={initialTitleRef} isOpen={props.show} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>New Conversation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb="1.5rem">
                        <FormControl isInvalid={errors.title ? true : undefined}>
                            <FormLabel>Title</FormLabel>
                            <Input
                                placeholder="Fill in title here.."
                                {...titleRest}
                                ref={e => {
                                    titleRef(e);
                                    initialTitleRef.current = e;
                                }}
                            />
                            <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.content ? true : undefined}>
                            <FormLabel mt="0.5rem">Content</FormLabel>
                            <RichTextEditor
                                mentions={participants}
                                showToolbar={true}
                                placeholder={placeholder}
                                onContentChange={onContentChanged}
                                maxLength={500}
                                register={contentReg}
                            />
                            <FormErrorMessage>{errors?.content?.message}</FormErrorMessage>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Flex width="100%" justify="space-between" align="center" gap="2rem">
                            <Box>
                                {currentUser.isInternal && (
                                    <Tooltip label={publish ? "Hide conversation from clients" : "Hidden from clients"}>
                                        <Button onClick={handleTogglePublish} variant="naked">
                                            {publish ? <IconEye /> : <IconEyeOff />}
                                        </Button>
                                    </Tooltip>
                                )}
                            </Box>
                            <Box>
                                <Button type="submit" colorScheme="blue" mr=".5rem" isLoading={createConversationQuery.isPending}>
                                    Save
                                </Button>
                                <Button type="button" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Box>
                        </Flex>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default CreateConversationDialog;
