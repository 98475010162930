import { Fetcher, Middleware, arrayRequestBody, ApiResponse } from "openapi-typescript-fetch";

import { components, paths } from "./generated/generated-api.ts";
import {
    FileContainer,
    applyParams,
    downloadFile,
    DownloadProgressCallback,
    execute,
    saveConfiguration,
    uploadFile,
    getQueryClient,
    UpdateFetchConfigOptions,
} from "am-tax-fe-core";
import { queryKeys } from "./queryKeys.ts";

export function matchRegion(regionLookup: RegionLookup, args: { clientId: string; engagementId?: string; projectId?: string }): Region | undefined {
    const { clientId, engagementId, projectId } = args;
    let region: Region | undefined = undefined;
    if (clientId && regionLookup.clientEndpoints) {
        region = regionLookup.clientEndpoints[clientId];
    }
    if (engagementId && regionLookup.engagementEndpoints) {
        region = regionLookup.engagementEndpoints[engagementId] || region;
    }
    if (projectId && regionLookup.projectEndpoints) {
        region = regionLookup.projectEndpoints[projectId] || region;
    }

    return region;
}

async function getRegionMatch(args: { clientId: string; engagementId: string; projectId?: string }): Promise<Region | undefined> {
    const regionLookup: RegionLookup = (await getQueryClient().fetchQuery({
        ...queryKeys.regions.lookup,
        queryFn: getRegionLookup,
    })) as unknown as RegionLookup;

    return matchRegion(regionLookup, args);
}

async function getEndpointUrl(args: { clientId: string; engagementId: string; projectId?: string }): Promise<UpdateFetchConfigOptions> {
    const region = await getRegionMatch(args);
    return { baseUrl: region?.endpointUrl };
}

async function getEngagementEndpointOpts(args: GetEngagementArgs): Promise<UpdateFetchConfigOptions> {
    const engagement: Engagement = (await getQueryClient().fetchQuery({
        ...queryKeys.engagements.byEngagementId(args.engagementId),
        queryFn: () => getEngagement(args.engagementId),
    })) as unknown as Engagement;
    return getEndpointUrl(engagement);
}
async function executeEngagementApi<T>(args: GetEngagementArgs, callback: () => Promise<ApiResponse<T>>): Promise<T> {
    const opts = await getEngagementEndpointOpts(args);
    return execute<T>(callback, opts);
}

async function getProjectEndpointOpts(args: GetProjectArgs): Promise<UpdateFetchConfigOptions> {
    const project: Project = (await getQueryClient().fetchQuery({
        ...queryKeys.projects.byProjectId(args.projectId),
        queryFn: () => getProject(args),
    })) as unknown as Project;
    return getEndpointUrl(project);
}
async function executeProjectApi<T>(args: GetProjectArgs, callback: () => Promise<ApiResponse<T>>): Promise<T> {
    const opts = await getProjectEndpointOpts(args);
    return execute<T>(callback, opts);
}

function engagementApi<T>(args: GetEngagementArgs, callback: () => Promise<ApiResponse<T>>): Promise<T> {
    return executeEngagementApi(args, callback);
}

function projectApi<T>(args: GetProjectArgs, callback: () => Promise<ApiResponse<T>>): Promise<T> {
    return executeProjectApi(args, callback);
}

function centralApi<T>(callback: () => Promise<ApiResponse<T>>): Promise<T> {
    return execute(callback);
}

// declare fetcher for paths
const fetcher = Fetcher.for<paths>();

export function configure(baseUrl: string, middlewares: Middleware[]) {
    saveConfiguration(fetcher, baseUrl, middlewares);
}

export const getRegions = async (): Promise<Region[]> => centralApi(() => _getRegions({}));
const getRegionsPath = "/api/Region";
export type Region = components["schemas"]["RegionResponse"];
const _getRegions = fetcher.path(getRegionsPath).method("get").create();

export const getRegionLookup = async (): Promise<RegionLookup> => centralApi(() => _getRegionLookup({}));
export type RegionLookup = components["schemas"]["RegionLookupResponse"];
const _getRegionLookup = fetcher.path("/api/Region/lookup").method("get").create();

// Client
export const getClients = async (): Promise<Client[]> => centralApi(() => _getClients({}));
export type Client = components["schemas"]["ClientResponse"];
const _getClients = fetcher.path("/api/client").method("get").create();

export const getClient = async (clientId: string): Promise<Client> => centralApi(() => _getClient({ clientId }));
const _getClient = fetcher.path("/api/client/{clientId}").method("get").create();

// Engagement Summary
export const getEngagementSummary = async (args: EngagementSummaryArgs): Promise<EngagementSummary> => engagementApi(args, () => _getEngagementSummary(args));
export type EngagementSummary = components["schemas"]["SummaryResponse"];
const getEngagementSummaryPath = "/api/engagement/{engagementId}/summary";
export type EngagementSummaryArgs = paths[typeof getEngagementSummaryPath]["get"]["parameters"]["path"] &
    paths[typeof getEngagementSummaryPath]["get"]["parameters"]["query"];
const _getEngagementSummary = fetcher.path(getEngagementSummaryPath).method("get").create();

// Project Summary
export const getProjectSummary = async (args: ProjectSummaryArgs): Promise<ProjectSummary> => projectApi(args, () => _getProjectSummary(args));
export type ProjectSummary = components["schemas"]["SummaryResponse"];
const getProjectSummaryPath = "/api/engagement/{engagementId}/project/{projectId}/summary";
export type ProjectSummaryArgs = paths[typeof getProjectSummaryPath]["get"]["parameters"]["path"];
const _getProjectSummary = fetcher.path(getProjectSummaryPath).method("get").create();

// Engagement Rollup
export const getEngagementRollup = async (): Promise<EngagementSummary[]> => centralApi(() => _getEngagementRollup({}));
const engagementRollupPath = "/api/engagements/summary";
const _getEngagementRollup = fetcher.path(engagementRollupPath).method("get").create();

// Engagement
export const getEngagementsByClientId = async (clientId: string): Promise<ClientEngagementResponse> => centralApi(() => _getEngagements({ clientId }));
export type ClientEngagementResponse = components["schemas"]["ClientEngagementResponse"];
export type Engagement = components["schemas"]["EngagementResponse"];
export type AgressoEngagement = components["schemas"]["AgressEngagementResponse"];
const _getEngagements = fetcher.path("/api/client/{clientId}/engagements").method("get").create();

export const createEngagement = async (args: PostEngagementArgs): Promise<Engagement> => centralApi(() => _createEngagement(args));
export type PostEngagementArgs = components["schemas"]["EngagementRequest"];
const _createEngagement = fetcher.path("/api/engagement").method("post").create();

export const createCustomEngagement = async (args: PostCustomEngagementArgs): Promise<Engagement> => centralApi(() => _createCustomEngagement(args));
const createCustomEngagementPath = "/api/customengagement";
export type PostCustomEngagementArgs = components["schemas"]["CustomEngagementRequest"];
const _createCustomEngagement = fetcher.path(createCustomEngagementPath).method("post").create();

export const updateEngagement = async (args: PutEngagementArgs): Promise<Engagement> => centralApi(() => _updateEngagement(args));
const updateEngagementPath = "/api/engagement/{engagementId}";
export type PutEngagementArgs = components["schemas"]["EngagementPutRequest"] & paths[typeof updateEngagementPath]["put"]["parameters"]["path"];
const _updateEngagement = fetcher.path(updateEngagementPath).method("put").create();

export const getEngagementTypes = async (): Promise<EngagementType[]> => centralApi(() => _getEngagementTypes({}));
export type EngagementType = components["schemas"]["EngagementTypeResponse"];
const _getEngagementTypes = fetcher.path("/api/engagementtype").method("get").create();

export const getEngagement = async (engagementId: string): Promise<Engagement> => centralApi(() => _getEngagement({ engagementId }));
const getEngagementPath = "/api/engagement/{engagementId}";
export type GetEngagementArgs = paths[typeof getEngagementPath]["get"]["parameters"]["path"];
const _getEngagement = fetcher.path(getEngagementPath).method("get").create();

export const requestAccessToEngagement = async (args: RequestAccessToEgagementArgs): Promise<unknown> => centralApi(() => _requestAccessToEgagement(args));
const requestAccessToEgagementPath = "/api/engagement/{engagementId}/accessrequest";
export type RequestAccessToEgagementArgs = paths[typeof requestAccessToEgagementPath]["post"]["parameters"]["path"];
const _requestAccessToEgagement = fetcher.path(requestAccessToEgagementPath).method("post").create();

const closeOrReopenEngagementPath = "/api/engagement/{engagementId}/activate";
export type CloseOrReopenEngagementArgs = paths[typeof closeOrReopenEngagementPath]["post"]["parameters"]["path"];
export type CloseOrReopenEngagementResponse = components["schemas"]["ToggleCloseEngagementResponse"];
const _closeOrReopenEngagement = fetcher.path(closeOrReopenEngagementPath).method("post").create();
export const closeOrReopenEngagement = async (args: CloseOrReopenEngagementArgs): Promise<CloseOrReopenEngagementResponse> =>
    centralApi(() => _closeOrReopenEngagement(args));

// Project
export const getProjectsByEngagementId = async (engagementId: string): Promise<EngagementProjects> => centralApi(() => _getProjects({ engagementId }));
export type EngagementProjects = components["schemas"]["EngagementProjectsResponse"];
export type Project = components["schemas"]["ProjectResponse"];
export type AgressoProject = components["schemas"]["AgressoProjectResponse"];
const _getProjects = fetcher.path("/api/engagement/{engagementId}/projects").method("get").create();

export const getProjectsByProjectCode = async (projectCode: string): Promise<EngagementProjects> =>
    centralApi(() => _getProjectsByProjectCode({ projectCode }));
const _getProjectsByProjectCode = fetcher.path("/api/projectCode/{projectCode}/projects").method("get").create();

export const createProject = async (args: PostProjectArgs): Promise<Project> => centralApi(() => _createProject(args));
const createProjectPath = "/api/engagement/{engagementId}/project";
export type PostProjectArgs = components["schemas"]["ProjectRequest"] & paths[typeof createProjectPath]["post"]["parameters"]["path"];
const _createProject = fetcher.path(createProjectPath).method("post").create();

export const createNonProvisionedEngagementProject = async (args: PostProjectNpeArgs): Promise<Project> => centralApi(() => _createNpeProject(args));
const createNpeProjectPath = "/api/engagement/project";
export type PostProjectNpeArgs = components["schemas"]["ProjectRequest"];
const _createNpeProject = fetcher.path(createNpeProjectPath).method("post").create();

export const createCustomProject = async (args: PostCustomProjectArgs): Promise<Project> => centralApi(() => _createCustomProject(args));
const createCustomProjectPath = "/api/engagement/{engagementId}/customproject";
export type PostCustomProjectArgs = components["schemas"]["CustomProjectRequest"] & paths[typeof createCustomProjectPath]["post"]["parameters"]["path"];
const _createCustomProject = fetcher.path(createCustomProjectPath).method("post").create();

export const updateProject = async (args: PutProjectArgs): Promise<Project> => centralApi(() => _updateProject(args));
const updateProjectPath = "/api/engagement/{engagementId}/project/{projectId}";
export type PutProjectArgs = components["schemas"]["ProjectPutRequest"] & paths[typeof updateProjectPath]["put"]["parameters"]["path"];
const _updateProject = fetcher.path(updateProjectPath).method("put").create();

export const getProject = async (args: GetProjectArgs): Promise<Project> => centralApi(() => _getProject(args));
const getProjectPath = "/api/engagement/{engagementId}/project/{projectId}";
export type GetProjectArgs = paths[typeof getProjectPath]["get"]["parameters"]["path"];
const _getProject = fetcher.path(getProjectPath).method("get").create();

export const requestAccessToProject = async (args: RequestAccessToProjectArgs): Promise<unknown> => centralApi(() => _requestAccessToProject(args));
const requestAccessToProjectPath = "/api/engagement/{engagementId}/project/{projectId}/accessrequest";
export type RequestAccessToProjectArgs = paths[typeof requestAccessToProjectPath]["post"]["parameters"]["path"];
const _requestAccessToProject = fetcher.path(requestAccessToProjectPath).method("post").create();

export const closeOrReopenProject = async (args: CloseOrReopenProjectArgs): Promise<CloseOrReopenProjectResponse> =>
    centralApi(() => _closeOrReopenProject(args));
const closeOrReopenProjectPath = "/api/engagement/{engagementId}/project/{projectId}/activate";
export type CloseOrReopenProjectArgs = paths[typeof closeOrReopenProjectPath]["post"]["parameters"]["path"];
export type CloseOrReopenProjectResponse = components["schemas"]["ToggleCloseProjectResponse"];
const _closeOrReopenProject = fetcher.path(closeOrReopenProjectPath).method("post").create();

// Search Clients
export const searchClients = async (args: ClientSearchArgs): Promise<ClientSearchResponse[]> => centralApi(() => _searchClients(args));
export type ClientSearchResponse = components["schemas"]["ClientSearchResponse"];
const searchClientsPath = "/api/client/search";
export type ClientSearchArgs = NonNullable<paths[typeof searchClientsPath]["get"]["parameters"]["query"]>;
const _searchClients = fetcher.path(searchClientsPath).method("get").create();

// Search Engagements
export const searchEngagements = async (args: EngagementSearchArgs): Promise<EngagementSearchResponse[]> => centralApi(() => _searchEngagements(args));
export type EngagementSearchResponse = components["schemas"]["EngagementSearchResponse"];
const searchEngagementsPath = "/api/engagement/search";
export type EngagementSearchArgs = NonNullable<paths[typeof searchEngagementsPath]["get"]["parameters"]["query"]>;
const _searchEngagements = fetcher.path(searchEngagementsPath).method("get").create();

// Search Projects
export const searchProjects = async (args: ProjectSearchArgs): Promise<ProjectSearchResponse[]> => centralApi(() => _searchProjects(args));
export type ProjectSearchResponse = components["schemas"]["ProjectSearchResponse"];
const searchProjectsPath = "/api/project/search";
export type ProjectSearchArgs = NonNullable<paths[typeof searchProjectsPath]["get"]["parameters"]["query"]>;
const _searchProjects = fetcher.path(searchProjectsPath).method("get").create();

// EngagementEvents
export const searchEngagementEvents = async (args: EngagementEventSearchArgs): Promise<EngagementEventSearchResults> =>
    engagementApi(args, () => _searchEngagementEvents(args));
const searchEngagementEventsPath = "/api/engagement/{engagementId}/event/search";
export type EngagementEventSearchResults = paths[typeof searchEngagementEventsPath]["get"]["responses"]["default"]["content"]["application/json"];
export type EngagementEventSearchParameters = paths[typeof searchEngagementEventsPath]["get"]["parameters"];
export type EngagementEventSearchArgs = EngagementEventSearchParameters["path"] & EngagementEventSearchParameters["query"];
export const _searchEngagementEvents = fetcher.path(searchEngagementEventsPath).method("get").create();

export const getEngagementEvent = async (args: GetEngagementEventArgs): Promise<Event> => engagementApi(args, () => _getEngagementEvent(args));
const getEngagementEventPath = "/api/engagement/{engagementId}/event/{eventId}";
export type Event = components["schemas"]["EventResponse"];
export type GetEngagementEventArgs = paths[typeof getEngagementEventPath]["get"]["parameters"]["path"];
export const _getEngagementEvent = fetcher.path(getEngagementEventPath).method("get").create();

export const postEngagementEvent = async (args: PostEngagementEventArgs): Promise<Event> => engagementApi(args, () => _postEngagementEvent(args));
const postEngagementEventPath = "/api/engagement/{engagementId}/event";
export type EngagementEventRequest = components["schemas"]["EventRequest"];
export type PostEngagementEventArgs = paths[typeof postEngagementEventPath]["post"]["parameters"]["path"] & components["schemas"]["EventRequest"];
export const _postEngagementEvent = fetcher.path(postEngagementEventPath).method("post").create();

export const putEngagementEvent = async (args: PutEngagementEventArgs): Promise<Event> => engagementApi(args, () => _putEngagementEvent(args));
const putEngagementEventPath = "/api/engagement/{engagementId}/event/{eventId}";
export type PutEngagementEventArgs = paths[typeof putEngagementEventPath]["put"]["parameters"]["path"] & components["schemas"]["EventRequest"];
export const _putEngagementEvent = fetcher.path(putEngagementEventPath).method("put").create();

export const deleteEngagementEvent = async (args: DeleteEngagementEventArgs): Promise<unknown> => engagementApi(args, () => _deleteEngagementEvent(args));
const deleteEngagementEventPath = "/api/engagement/{engagementId}/event/{eventId}";
export type DeleteEngagementEventArgs = paths[typeof deleteEngagementEventPath]["delete"]["parameters"]["path"];
export const _deleteEngagementEvent = fetcher.path(deleteEngagementEventPath).method("delete").create();

// ProjectEvents
export const searchProjectEvents = async (args: ProjectEventSearchArgs): Promise<ProjectEventSearchResults> =>
    projectApi(args, () => _searchProjectEvents(args));
const searchProjectEventsPath = "/api/engagement/{engagementId}/project/{projectId}/event/search";
export type ProjectEventSearchResults = paths[typeof searchProjectEventsPath]["get"]["responses"]["default"]["content"]["application/json"];
export type ProjectEventSearchParameters = paths[typeof searchProjectEventsPath]["get"]["parameters"];
export type ProjectEventSearchArgs = ProjectEventSearchParameters["path"] & ProjectEventSearchParameters["query"];
export const _searchProjectEvents = fetcher.path(searchProjectEventsPath).method("get").create();

export const getProjectEvent = async (args: GetProjectEventArgs): Promise<Event> => projectApi(args, () => _getProjectEvent(args));
const getProjectEventPath = "/api/engagement/{engagementId}/project/{projectId}/event/{eventId}";
export type GetProjectEventArgs = paths[typeof getProjectEventPath]["get"]["parameters"]["path"];
export const _getProjectEvent = fetcher.path(getProjectEventPath).method("get").create();

export const postProjectEvent = async (args: PostProjectEventArgs): Promise<Event> => projectApi(args, () => _postProjectEvent(args));
const postProjectEventPath = "/api/engagement/{engagementId}/project/{projectId}/event";
export type ProjectEventRequest = components["schemas"]["EventRequest"];
export type PostProjectEventArgs = paths[typeof postProjectEventPath]["post"]["parameters"]["path"] & components["schemas"]["EventRequest"];
export const _postProjectEvent = fetcher.path(postProjectEventPath).method("post").create();

export const putProjectEvent = async (args: PutProjectEventArgs): Promise<Event> => projectApi(args, () => _putProjectEvent(args));
const putProjectEventPath = "/api/engagement/{engagementId}/project/{projectId}/event/{eventId}";
export type PutProjectEventArgs = paths[typeof putProjectEventPath]["put"]["parameters"]["path"] & components["schemas"]["EventRequest"];
export const _putProjectEvent = fetcher.path(putProjectEventPath).method("put").create();

export const deleteProjectEvent = async (args: DeleteProjectEventArgs): Promise<unknown> => projectApi(args, () => _deleteProjectEvent(args));
const deleteProjectEventPath = "/api/engagement/{engagementId}/project/{projectId}/event/{eventId}";
export type DeleteProjectEventArgs = paths[typeof deleteProjectEventPath]["delete"]["parameters"]["path"];
export const _deleteProjectEvent = fetcher.path(deleteProjectEventPath).method("delete").create();

// Engagement Requests
export const getEngagementRequestsByEngagementId = async (args: GetEngagementRequestsByEngagementIdArgs): Promise<Request[]> =>
    engagementApi(args, () => _getEngagementRequests(args));
const getEngagementRequestsByEngagementIdPath = "/api/engagement/{engagementId}/requests";
export type GetEngagementRequestsByEngagementIdArgs = paths[typeof getEngagementRequestsByEngagementIdPath]["get"]["parameters"]["path"] &
    paths[typeof getEngagementRequestsByEngagementIdPath]["get"]["parameters"]["query"];
export type Request = components["schemas"]["RequestResponse"];
export type Document = components["schemas"]["DocumentResponse"];
const _getEngagementRequests = fetcher.path(getEngagementRequestsByEngagementIdPath).method("get").create();

export const getEngagementRequest = async (args: GetEngagementRequestArgs): Promise<Request> => engagementApi(args, () => _getEngagementRequest(args));
const getEngagementRequestPath = "/api/engagement/{engagementId}/request/{requestId}";
export type GetEngagementRequestArgs = paths[typeof getEngagementRequestPath]["get"]["parameters"]["path"];
const _getEngagementRequest = fetcher.path(getEngagementRequestPath).method("get").create();

export const postEngagementRequest = async (args: PostEngagementRequestArgs): Promise<Request> => engagementApi(args, () => _postEngagementRequest(args));
export type PostEngagementRequestArgs = components["schemas"]["RequestModel"] & { engagementId: string };
const _postEngagementRequest = fetcher.path("/api/engagement/{engagementId}/request").method("post").create();

export const putEngagementRequest = async (args: PutEngagementRequestArgs): Promise<Request> => engagementApi(args, () => _putEngagementRequest(args));
export type PutEngagementRequestArgs = PostEngagementRequestArgs & { requestId: string };
const _putEngagementRequest = fetcher.path("/api/engagement/{engagementId}/request/{requestId}").method("put").create();

export const deleteEngagementRequest = async (args: DeleteEngagementRequestArgs): Promise<unknown> => engagementApi(args, () => _deleteEngagementRequest(args));
export type DeleteEngagementRequestArgs = { engagementId: string; requestId: string };
const _deleteEngagementRequest = fetcher.path("/api/engagement/{engagementId}/request/{requestId}").method("delete").create();

export type UploadDocumentsToEngagementRequestArgs = {
    engagementId: string;
    requestId: string;
    fileContainer: FileContainer;
};
export const uploadDocumentToEngagementRequest = async (args: UploadDocumentsToEngagementRequestArgs): Promise<Document> => {
    const opts = await getEngagementEndpointOpts(args);
    return uploadFile(`/api/engagement/${args.engagementId}/request/${args.requestId}/document`, args.fileContainer, opts);
};

// Project Requests
export const getProjectRequestsByProjectId = async (args: GetProjectRequestsByProjectIdArgs): Promise<Request[]> =>
    projectApi(args, () => _getProjectRequests(args));
const getProjectRequestsByProjectIdPath = "/api/engagement/{engagementId}/project/{projectId}/requests";
export type GetProjectRequestsByProjectIdArgs = paths[typeof getProjectRequestsByProjectIdPath]["get"]["parameters"]["path"];
const _getProjectRequests = fetcher.path(getProjectRequestsByProjectIdPath).method("get").create();

export const getProjectRequest = async (args: GetProjectRequestArgs): Promise<Request> => projectApi(args, () => _getProjectRequest(args));
const getProjectRequestPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}";
export type GetProjectRequestArgs = paths[typeof getProjectRequestPath]["get"]["parameters"]["path"];
const _getProjectRequest = fetcher.path(getProjectRequestPath).method("get").create();

export const postProjectRequest = async (args: PostProjectRequestArgs): Promise<Request> => projectApi(args, () => _postProjectRequest(args));
export type PostProjectRequestArgs = components["schemas"]["RequestModel"] & { engagementId: string; projectId: string };
const _postProjectRequest = fetcher.path("/api/engagement/{engagementId}/project/{projectId}/request").method("post").create();

export const putProjectRequest = async (args: PutProjectRequestArgs): Promise<Request> => projectApi(args, () => _putProjectRequest(args));
export type PutProjectRequestArgs = PostProjectRequestArgs & { requestId: string };
const _putProjectRequest = fetcher.path("/api/engagement/{engagementId}/project/{projectId}/request/{requestId}").method("put").create();

export const deleteProjectRequest = async (args: DeleteProjectRequestArgs): Promise<unknown> => projectApi(args, () => _deleteProjectRequest(args));
export type DeleteProjectRequestArgs = { engagementId: string; projectId: string; requestId: string };
const _deleteProjectRequest = fetcher.path("/api/engagement/{engagementId}/project/{projectId}/request/{requestId}").method("delete").create();

export type UploadDocumentsToProjectRequestArgs = UploadDocumentsToEngagementRequestArgs & {
    projectId: string;
};
export const uploadDocumentToProjectRequest = async (args: UploadDocumentsToProjectRequestArgs): Promise<Document> => {
    const opts = await getProjectEndpointOpts(args);
    return uploadFile(`/api/engagement/${args.engagementId}/project/${args.projectId}/request/${args.requestId}/document`, args.fileContainer, opts);
};

// Engagement Documents
export type DocumentsResponse = components["schemas"]["EngagementDocumentsResponse"];

export const getEngagementDocuments = async (args: GetEngagementDocumentsArgs): Promise<DocumentsResponse> =>
    engagementApi(args, () => _getEngagementDocuments(args));
const getEngagementDocumentsPath = "/api/engagement/{engagementId}/root/{rootFolderId}/folder/{folderId}";
export type GetEngagementDocumentsArgs = paths[typeof getEngagementDocumentsPath]["get"]["parameters"]["path"];
const _getEngagementDocuments = fetcher.path(getEngagementDocumentsPath).method("get").create();

export const deleteEngagementDocument = async (args: DeleteEngagementDocumentArgs): Promise<unknown> =>
    engagementApi(args, () => _deleteDocumentFromEngagement(args));
const deleteEngagementDocumentPath = "/api/engagement/{engagementId}/document/{documentId}";
export type DeleteEngagementDocumentArgs = paths[typeof deleteEngagementDocumentPath]["delete"]["parameters"]["path"];
export const _deleteDocumentFromEngagement = fetcher.path(deleteEngagementDocumentPath).method("delete").create();

export const downloadEngagementDocument = async (args: DownloadEngagementDocumentArgs): Promise<Blob> => _downloadEngagementDocument(args);
const downloadEngagementDocumentPath = "/api/engagement/{engagementId}/document/{documentId}/version/{documentVersionId}/content";
export type DownloadEngagementDocumentArgs = paths[typeof downloadEngagementDocumentPath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadEngagementDocument = async (args: DownloadEngagementDocumentArgs): Promise<Blob> => {
    const opts = await getEngagementEndpointOpts(args);
    const response = await downloadFile(applyParams(downloadEngagementDocumentPath, args), args.progressCallback, opts);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The document could not be downloaded.");
    }
};

export const createEngagementFolders = async (args: CreateEngagementFoldersArgs): Promise<Record<string, string>> => {
    return engagementApi(args, () => _createEngagementFolders(arrayRequestBody(args.folderPaths, args))) as Promise<Record<string, string>>;
};
const commonEngagementFoldersApiPath = "/api/engagement/{engagementId}/folder/{folderId}";
export type CreateEngagementFoldersArgs = paths[typeof commonEngagementFoldersApiPath]["post"]["parameters"]["path"] & { folderPaths: string[] };
const _createEngagementFolders = fetcher.path(commonEngagementFoldersApiPath).method("post").create();

export const renameEngagementFolder = async (args: RenameEngagementFolderArgs): Promise<unknown> => engagementApi(args, () => _renameEngagementFolder(args));
export type Folder = components["schemas"]["FolderModel"];
export type RenameEngagementFolderArgs = paths[typeof commonEngagementFoldersApiPath]["put"]["parameters"]["path"] & Folder;
const _renameEngagementFolder = fetcher.path(commonEngagementFoldersApiPath).method("put").create();

export const deleteEngagementFolder = async (args: DeleteEngagementFolderArgs): Promise<unknown> => engagementApi(args, () => _deleteEngagementFolder(args));
export type DeleteEngagementFolderArgs = paths[typeof commonEngagementFoldersApiPath]["delete"]["parameters"]["path"];
const _deleteEngagementFolder = fetcher.path(commonEngagementFoldersApiPath).method("delete").create();

export const uploadEngagementDocument = async (args: UploadEngagementDocumentArgs): Promise<Document> => {
    const opts = await getEngagementEndpointOpts(args);
    return uploadFile(applyParams(uploadEngagementDocumentPath, args), args.fileContainer, opts);
};
const uploadEngagementDocumentPath = "/api/engagement/{engagementId}/folder/{folderId}/document";
export type UploadEngagementDocumentArgs = paths[typeof uploadEngagementDocumentPath]["post"]["parameters"]["path"] & { fileContainer: FileContainer };

export const getEngagementDocumentPreviewLink = async (args: GetEngagementDocumentPreviewLinkArgs): Promise<string> =>
    engagementApi(args, () => _getEngagementDocumentPreviewLink(args));
export type GetEngagementDocumentPreviewLinkArgs = { engagementId: string; documentId: string };
const _getEngagementDocumentPreviewLink = fetcher.path("/api/engagement/{engagementId}/document/{documentId}/previewlink").method("get").create();

export const getEngagementBoxFolderLink = async (args: GetEngagementBoxFolderLinkArgs): Promise<string> =>
    engagementApi(args, () => _getEngagementSharedLink(args));
export type GetEngagementBoxFolderLinkArgs = { engagementId: string; folderId: string };
const _getEngagementSharedLink = fetcher.path("/api/engagement/{engagementId}/folder/{folderId}/link").method("get").create();

// Project Documents
export const getProjectDocuments = async (args: GetProjectDocumentsArgs): Promise<DocumentsResponse> => projectApi(args, () => _getProjectDocuments(args));
const getProjectDocumentsPath = "/api/engagement/{engagementId}/project/{projectId}/root/{rootFolderId}/folder/{folderId}";
export type GetProjectDocumentsArgs = paths[typeof getProjectDocumentsPath]["get"]["parameters"]["path"];
const _getProjectDocuments = fetcher.path(getProjectDocumentsPath).method("get").create();

export const deleteProjectDocument = async (args: DeleteProjectDocumentArgs): Promise<unknown> => projectApi(args, () => _deleteDocumentFromProject(args));
const deleteProjectDocumentPath = "/api/engagement/{engagementId}/project/{projectId}/document/{documentId}";
export type DeleteProjectDocumentArgs = paths[typeof deleteProjectDocumentPath]["delete"]["parameters"]["path"];
export const _deleteDocumentFromProject = fetcher.path(deleteProjectDocumentPath).method("delete").create();

export const downloadProjectDocument = async (args: DownloadProjectDocumentArgs): Promise<Blob> => _downloadProjectDocument(args);
const downloadProjectDocumentPath = "/api/engagement/{engagementId}/project/{projectId}/document/{documentId}/version/{documentVersionId}/content";
export type DownloadProjectDocumentArgs = paths[typeof downloadProjectDocumentPath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadProjectDocument = async (args: DownloadProjectDocumentArgs): Promise<Blob> => {
    const opts = await getProjectEndpointOpts(args);
    const response = await downloadFile(applyParams(downloadProjectDocumentPath, args), args.progressCallback, opts);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The document could not be downloaded.");
    }
};

export const createProjectFolders = async (args: CreateProjectFoldersArgs): Promise<Record<string, string>> => {
    return projectApi(args, () => _createProjectFolders(arrayRequestBody(args.folderPaths, args))) as Promise<Record<string, string>>;
};
const commonProjectFoldersApiPath = "/api/engagement/{engagementId}/project/{projectId}/folder/{folderId}";
export type CreateProjectFoldersArgs = paths[typeof commonProjectFoldersApiPath]["post"]["parameters"]["path"] & { folderPaths: string[] };
const _createProjectFolders = fetcher.path(commonProjectFoldersApiPath).method("post").create();

export const renameProjectFolder = async (args: RenameProjectFolderArgs): Promise<unknown> => projectApi(args, () => _renameProjectFolder(args));
export type RenameProjectFolderArgs = paths[typeof commonProjectFoldersApiPath]["put"]["parameters"]["path"] & Folder;
const _renameProjectFolder = fetcher.path(commonProjectFoldersApiPath).method("put").create();

export const deleteProjectFolder = async (args: DeleteProjectFolderArgs): Promise<unknown> => projectApi(args, () => _deleteProjectFolder(args));
export type DeleteProjectFolderArgs = paths[typeof commonProjectFoldersApiPath]["delete"]["parameters"]["path"];
const _deleteProjectFolder = fetcher.path(commonProjectFoldersApiPath).method("delete").create();

export const uploadProjectDocument = async (args: UploadProjectDocumentArgs): Promise<Document> => {
    const opts = await getProjectEndpointOpts(args);
    return uploadFile(applyParams(uploadProjectDocumentPath, args), args.fileContainer, opts);
};
const uploadProjectDocumentPath = "/api/engagement/{engagementId}/project/{projectId}/folder/{folderId}/document";
export type UploadProjectDocumentArgs = paths[typeof uploadProjectDocumentPath]["post"]["parameters"]["path"] & { fileContainer: FileContainer };

export const getProjectDocumentPreviewLink = async (args: GetProjectDocumentPreviewLinkArgs): Promise<string> =>
    projectApi(args, () => _getProjectDocumentPreviewLink(args));
export type GetProjectDocumentPreviewLinkArgs = { engagementId: string; projectId: string; documentId: string };
const _getProjectDocumentPreviewLink = fetcher
    .path("/api/engagement/{engagementId}/project/{projectId}/document/{documentId}/previewlink")
    .method("get")
    .create();

export const getProjectBoxFolderLink = async (args: GetProjectBoxFolderLinkArgs): Promise<string> => projectApi(args, () => _getProjectBoxFolderLink(args));
export type GetProjectBoxFolderLinkArgs = { engagementId: string; projectId: string; folderId: string };
const _getProjectBoxFolderLink = fetcher.path("/api/engagement/{engagementId}/project/{projectId}/folder/{folderId}/link").method("get").create();

// Bulk Event Templates
export const downloadEngagementEventTemplate = async (args: DownloadEngagementEventTemplateArgs): Promise<Blob> => _downloadEngagementEventTemplate(args);
const downloadEngagementEventTemplatePath = "/api/engagement/{engagementId}/event/template/download";
export type DownloadEngagementEventTemplateArgs = paths[typeof downloadEngagementEventTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadEngagementEventTemplate = async (args: DownloadEngagementEventTemplateArgs): Promise<Blob> => {
    const opts = await getEngagementEndpointOpts(args);
    const response = await downloadFile(applyParams(downloadEngagementEventTemplatePath, args), args.progressCallback, opts);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};

export const downloadProjectEventTemplate = async (args: DownloadProjectEventTemplateArgs): Promise<Blob> => _downloadProjectEventTemplate(args);
const downloadProjectEventTemplatePath = "/api/engagement/{engagementId}/project/{projectId}/event/template/download";
export type DownloadProjectEventTemplateArgs = paths[typeof downloadProjectEventTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadProjectEventTemplate = async (args: DownloadProjectEventTemplateArgs): Promise<Blob> => {
    const opts = await getProjectEndpointOpts(args);
    const response = await downloadFile(applyParams(downloadProjectEventTemplatePath, args), args.progressCallback, opts);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};

// Bulk Request Templates
export const downloadEngagementRequestTemplate = async (args: DownloadEngagementRequestTemplateArgs): Promise<Blob> => _downloadEngagementRequestTemplate(args);
const downloadEngagementRequestTemplatePath = "/api/engagement/{engagementId}/request/template/download";
export type DownloadEngagementRequestTemplateArgs = paths[typeof downloadEngagementRequestTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadEngagementRequestTemplate = async (args: DownloadEngagementRequestTemplateArgs): Promise<Blob> => {
    const opts = await getEngagementEndpointOpts(args);
    const response = await downloadFile(applyParams(downloadEngagementRequestTemplatePath, args), args.progressCallback, opts);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};

export const downloadProjectRequestTemplate = async (args: DownloadProjectRequestTemplateArgs): Promise<Blob> => _downloadProjectRequestTemplate(args);
const downloadProjectRequestTemplatePath = "/api/engagement/{engagementId}/project/{projectId}/request/template/download";
export type DownloadProjectRequestTemplateArgs = paths[typeof downloadProjectRequestTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadProjectRequestTemplate = async (args: DownloadProjectRequestTemplateArgs): Promise<Blob> => {
    const opts = await getProjectEndpointOpts(args);
    const response = await downloadFile(applyParams(downloadProjectRequestTemplatePath, args), args.progressCallback, opts);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};

//Bulk Upload Requests
export const bulkUploadEngagementRequests = async (args: BulkUploadEngagementRequestsArgs): Promise<unknown> => {
    const opts = await getEngagementEndpointOpts(args);
    return uploadFile(applyParams(bulkUploadEngagementRequestsPath, args), args.fileContainer, opts);
};
const bulkUploadEngagementRequestsPath = "/api/engagement/{engagementId}/request/template/upload";
export type BulkUploadEngagementRequestsArgs = paths[typeof bulkUploadEngagementRequestsPath]["post"]["parameters"]["path"] & {
    fileContainer: FileContainer;
};

export const bulkUploadProjectRequests = async (args: BulkUploadProjectRequestsArgs): Promise<unknown> => {
    const opts = await getProjectEndpointOpts(args);
    return uploadFile(applyParams(bulkUploadProjectRequestsPath, args), args.fileContainer, opts);
};
const bulkUploadProjectRequestsPath = "/api/engagement/{engagementId}/project/{projectId}/request/template/upload";
export type BulkUploadProjectRequestsArgs = paths[typeof bulkUploadProjectRequestsPath]["post"]["parameters"]["path"] & {
    fileContainer: FileContainer;
};

//Bulk Upload Events
export const bulkUploadEngagementEvents = async (args: BulkUploadEngagementEventsArgs): Promise<unknown> => {
    const opts = await getEngagementEndpointOpts(args);
    return uploadFile(applyParams(bulkUploadEngagementEventsPath, args), args.fileContainer, opts);
};
const bulkUploadEngagementEventsPath = "/api/engagement/{engagementId}/event/template/upload";
export type BulkUploadEngagementEventsArgs = paths[typeof bulkUploadEngagementEventsPath]["post"]["parameters"]["path"] & {
    fileContainer: FileContainer;
};

export const bulkUploadProjectEvents = async (args: BulkUploadProjectEventsArgs): Promise<unknown> => {
    const opts = await getProjectEndpointOpts(args);
    return uploadFile(applyParams(bulkUploadProjectEventsPath, args), args.fileContainer, opts);
};
const bulkUploadProjectEventsPath = "/api/engagement/{engagementId}/project/{projectId}/event/template/upload";
export type BulkUploadProjectEventsArgs = paths[typeof bulkUploadProjectEventsPath]["post"]["parameters"]["path"] & {
    fileContainer: FileContainer;
};

// States
export const getStates = async (): Promise<State[]> => centralApi(() => _getStates({}));
export type State = components["schemas"]["USStateResponse"];
const _getStates = fetcher.path("/api/states").method("get").create();

// Request Statuses
export const getRequestStatuses = async (): Promise<RequestStatus[]> => centralApi(() => _getRequestSatuses({}));
export type RequestStatus = components["schemas"]["RequestStatusResponse"];
const _getRequestSatuses = fetcher.path("/api/engagement/request/requeststatuses").method("get").create();

// Engagement Activities
export const getEngagementActivityTypes = async (args: GetEngagementActivityTypesArgs): Promise<ActivityType[]> =>
    centralApi(() => _getEngagementActivityTypes(args));
const getEngagementActivityTypesPath = "/api/engagement/{engagementId}/activitytype";
export type GetEngagementActivityTypesArgs = paths[typeof getEngagementActivityTypesPath]["get"]["parameters"]["path"];
export type ActivityType = components["schemas"]["ActivityTypeResponse"];
const _getEngagementActivityTypes = fetcher.path(getEngagementActivityTypesPath).method("get").create();

export const searchEngagementActivities = async (args: EngagementActivitySearchArgs): Promise<ActivitySearchResponse> =>
    engagementApi(args, () => _searchEngagementActivities(args));
export type ActivitySearchResponse = components["schemas"]["ActivityResponse"];
export type Activity = components["schemas"]["SingleActivityResponse"];
const searchEngagementActivitiesPath = "/api/engagement/{engagementId}/activity";
type SearchEngagementActivitiesParamsType = paths[typeof searchEngagementActivitiesPath]["get"]["parameters"];
export type EngagementActivitySearchArgs = SearchEngagementActivitiesParamsType["path"] & SearchEngagementActivitiesParamsType["query"];
const _searchEngagementActivities = fetcher.path(searchEngagementActivitiesPath).method("get").create();

// Project Activities
export const getProjectActivityTypes = async (args: GetProjectActivityTypesArgs): Promise<ActivityType[]> => centralApi(() => _getProjectActivityTypes(args));
const getProjectActivityTypesPath = "/api/engagement/{engagementId}/project/{projectId}/activitytype";
export type GetProjectActivityTypesArgs = paths[typeof getProjectActivityTypesPath]["get"]["parameters"]["path"];
const _getProjectActivityTypes = fetcher.path(getProjectActivityTypesPath).method("get").create();

export const searchProjectActivities = async (args: ProjectActivitySearchArgs): Promise<ActivitySearchResponse> =>
    projectApi(args, () => _searchProjectActivities(args));
const searchProjectActivitiesPath = "/api/engagement/{engagementId}/project/{projectId}/activity";
type SearchProjectActivitiesParamsType = paths[typeof searchProjectActivitiesPath]["get"]["parameters"];
export type ProjectActivitySearchArgs = SearchProjectActivitiesParamsType["path"] & SearchProjectActivitiesParamsType["query"];
const _searchProjectActivities = fetcher.path(searchProjectActivitiesPath).method("get").create();

// Nav Items
export const getNavItems = async (): Promise<NavItemResponse[]> => centralApi(() => _getNavItems({}));
export type NavItemResponse = components["schemas"]["NavItemResponse"];
const getNavItemsPath = "/api/navitems";
const _getNavItems = fetcher.path(getNavItemsPath).method("get").create();

export const getNavItemsByEngagement = async (args: GetNavItemsByEngagemenArgs): Promise<NavItemResponse[]> => centralApi(() => _getNavItemsByEngagement(args));
const getNavItemsByEngagementPath = "/api/engagement/{engagementId}/navitems";
export type GetNavItemsByEngagemenArgs = paths[typeof getNavItemsByEngagementPath]["get"]["parameters"]["path"];
const _getNavItemsByEngagement = fetcher.path(getNavItemsByEngagementPath).method("get").create();

export const getNavItemsByProject = async (args: GetNavItemsByProjectArgs): Promise<NavItemResponse[]> => centralApi(() => _getNavItemsByProject(args));
const getNavItemsByProjectPath = "/api/engagement/{engagementId}/project/{projectId}/navitems";
export type GetNavItemsByProjectArgs = paths[typeof getNavItemsByProjectPath]["get"]["parameters"]["path"];
const _getNavItemsByProject = fetcher.path(getNavItemsByProjectPath).method("get").create();

// Roles
export const getRoles = async (): Promise<Role[]> => centralApi(() => _getRoles({}));
export type Role = components["schemas"]["RoleResponse"];
const _getRoles = fetcher.path("/api/user/roles").method("get").create();

export const getMyRoleOnEngagement = async (args: GetMyRoleOnEngagementArgs): Promise<Role> => centralApi(() => _getMyRoleOnEngagement(args));
const getMyRoleOnEngagementPath = "/api/user/engagement/{engagementId}/role";
export type GetMyRoleOnEngagementArgs = paths[typeof getMyRoleOnEngagementPath]["get"]["parameters"]["path"];
const _getMyRoleOnEngagement = fetcher.path(getMyRoleOnEngagementPath).method("get").create();

export const getMyRoleOnProject = async (args: GetMyRoleOnProjectArgs): Promise<Role> => centralApi(() => _getMyRoleOnProject(args));
const getMyRoleOnProjectPath = "/api/user/engagement/{engagementId}/project/{projectId}/role";
export type GetMyRoleOnProjectArgs = paths[typeof getMyRoleOnProjectPath]["get"]["parameters"]["path"];
const _getMyRoleOnProject = fetcher.path(getMyRoleOnProjectPath).method("get").create();

// Permissions
export const getPermissionsByRole = async (): Promise<PermissionsByRoleResponse> => centralApi(() => _getPermissionsByRole({}));
const getPermissionsByRolePath = "/api/permissions";
export type PermissionsByRoleResponse = {
    [key: string]: string[] | undefined;
};
const _getPermissionsByRole = fetcher.path(getPermissionsByRolePath).method("get").create();

// Users
export const getCurrentUser = async (): Promise<User> => centralApi(() => _getCurrentUser({}));
export type User = components["schemas"]["UserResponse"];
export const _getCurrentUser = fetcher.path("/api/user").method("get").create();

export const searchUsers = async (args: NonNullable<SearchUsersArgs>): Promise<User[]> => centralApi(() => _searchUsers(args));
const searchUsersPath = "/api/user/search";
export type SearchUsersArgs = NonNullable<paths[typeof searchUsersPath]["get"]["parameters"]["query"]>;
export const _searchUsers = fetcher.path(searchUsersPath).method("get").create();
export enum UserSearchLevel {
    LMD = "LMD",
    LD = "LD",
}

// GDPR Consent
export const getGdprConsent = async (): Promise<GdprConsent> => centralApi(() => _getGdprConsent({}));
export type GdprConsent = components["schemas"]["GdprConsent"];
export const _getGdprConsent = fetcher.path("/api/user/gdprConsent").method("get").create();

export const saveGdprConsent = async (args: GdprConsent): Promise<unknown> => centralApi(() => _saveGdprConsent(args));
export const _saveGdprConsent = fetcher.path("/api/user/gdprConsent").method("post").create();

// Engagement Users
export const getEngagementUsers = async (args: GetEngagementUsersArgs): Promise<Participant[]> => centralApi(() => _getEngagementUsers(args));
export type Participant = components["schemas"]["ParticipantResponse"];
const getEngagementUsersPath = "/api/user/engagement/{engagementId}";
export type GetEngagementUsersArgs = paths[typeof getEngagementUsersPath]["get"]["parameters"]["path"] &
    paths[typeof getEngagementUsersPath]["get"]["parameters"]["query"];
export const _getEngagementUsers = fetcher.path(getEngagementUsersPath).method("get").create();

export const deleteUserFromEngagement = async (args: DeleteUserFromEngagementArgs): Promise<unknown> => centralApi(() => _deleteUserFromEngagement(args));
const deleteUserFromEngagementPath = "/api/user/engagement/{engagementId}/user/{userId}";
export type DeleteUserFromEngagementArgs = paths[typeof deleteUserFromEngagementPath]["delete"]["parameters"]["path"];
export const _deleteUserFromEngagement = fetcher.path(deleteUserFromEngagementPath).method("delete").create();

export const addExternalUserToEngagement = async (args: AddExternalUserToEngagementArgs): Promise<User> => centralApi(() => _addExternalUser(args));
const addExternalUserPath = "/api/user/engagement/{engagementId}/externaluser";
export type ExternalUser = components["schemas"]["ExternalUser"];
export type AddExternalUserToEngagementArgs = paths[typeof addExternalUserPath]["post"]["parameters"]["path"] & ExternalUser;
export const _addExternalUser = fetcher.path(addExternalUserPath).method("post").create();

export const addOrUpdateUserOnEngagement = async (args: AddUpdateUserOnEngagementArgs): Promise<unknown> => {
    const params: AddUpdateUserOnEngagementParams = arrayRequestBody([...args.userRolesAndContactTypes], args);
    return centralApi(() => _addUpdateUserOnEngagement(params));
};
const addUpdateUserOnEngagementPath = "/api/user/engagement/{engagementId}";
export type UserRole = components["schemas"]["UserRole"];
export type AddUpdateUserOnEngagementArgs = paths[typeof addUpdateUserOnEngagementPath]["put"]["parameters"]["path"] & {
    userRolesAndContactTypes: Array<UserRole>;
};
export type AddUpdateUserOnEngagementParams = paths[typeof addUpdateUserOnEngagementPath]["put"]["parameters"]["path"] & Array<UserRole>;
export const _addUpdateUserOnEngagement = fetcher.path(addUpdateUserOnEngagementPath).method("put").create();

// Project Users
export const getProjectUsers = async (args: GetProjectUsersArgs): Promise<Participant[]> => centralApi(() => _getProjectUsers(args));
const getProjectUsersPath = "/api/user/engagement/{engagementId}/project/{projectId}";
export type GetProjectUsersArgs = paths[typeof getProjectUsersPath]["get"]["parameters"]["path"];
export const _getProjectUsers = fetcher.path(getProjectUsersPath).method("get").create();

export const deleteUserFromProject = async (args: DeleteUserFromProjectArgs): Promise<unknown> => centralApi(() => _deleteUserFromProject(args));
const deleteUserFromProjectPath = "/api/user/engagement/{engagementId}/project/{projectId}/user/{userId}";
export type DeleteUserFromProjectArgs = paths[typeof deleteUserFromProjectPath]["delete"]["parameters"]["path"];
export const _deleteUserFromProject = fetcher.path(deleteUserFromProjectPath).method("delete").create();

export const addExternalUserToProject = async (args: AddExternalUserToProjectArgs): Promise<User> => centralApi(() => _addExternalUserToProject(args));
const addExternalUserToProjectPath = "/api/user/engagement/{engagementId}/project/{projectId}/externaluser";
export type AddExternalUserToProjectArgs = paths[typeof addExternalUserToProjectPath]["post"]["parameters"]["path"] & ExternalUser;
export const _addExternalUserToProject = fetcher.path(addExternalUserToProjectPath).method("post").create();

export const addOrUpdateUserOnProject = async (args: AddUpdateUserOnProjectArgs): Promise<unknown> => {
    const params: AddUpdateUserOnProjectParams = arrayRequestBody([...args.userRolesAndContactTypes], args);
    return centralApi(() => _addUpdateUserOnProject(params));
};
const addUpdateUserOnProjectPath = "/api/user/engagement/{engagementId}/project/{projectId}";
export type AddUpdateUserOnProjectArgs = paths[typeof addUpdateUserOnProjectPath]["put"]["parameters"]["path"] & { userRolesAndContactTypes: Array<UserRole> };
export type AddUpdateUserOnProjectParams = paths[typeof addUpdateUserOnProjectPath]["put"]["parameters"]["path"] & Array<UserRole>;
export const _addUpdateUserOnProject = fetcher.path(addUpdateUserOnProjectPath).method("put").create();

// Feature Flags
export const getFeatureFlags = async (): Promise<FeatureFlagResponse> => centralApi(() => _getFeatureFlags({}));
export type FeatureFlagResponse = { [key: string]: string };
const getFeatureFlagsPath = "/api/features";
const _getFeatureFlags = fetcher.path(getFeatureFlagsPath).method("get").create();

// Global Notification Settings
export const getNotificationOptions = async (): Promise<Array<NotificationGroupOptionResponse>> => centralApi(() => _getNotificationOptions({}));
export type NotificationGroupOptionResponse = components["schemas"]["NotificationGroupOptionResponse"];
const getNotificationOptionsPath = "/api/notification/options";
const _getNotificationOptions = fetcher.path(getNotificationOptionsPath).method("get").create();

export const getGlobalNotificationSettings = async (): Promise<GlobalNotificationSettingsResponse> => centralApi(() => _getGlobalNotificationSettings({}));
export type GlobalNotificationSettingsResponse = components["schemas"]["GlobalNotificationSettingsResponse"];
const getGlobalNotificationSettingsPath = "/api/notification/settings";
const _getGlobalNotificationSettings = fetcher.path(getGlobalNotificationSettingsPath).method("get").create();

export const updateGlobalNotificationSettings = async (args: Array<GlobalNotificationSettingsRequest>): Promise<GlobalNotificationSettingsResponse> =>
    centralApi(() => _updateGlobalNotificationSettings(args));
export type GlobalNotificationSettingsRequest = components["schemas"]["GlobalNotificationSettingsRequest"];
const updateGlobalNotificationSettingsPath = "/api/notification/settings";
const _updateGlobalNotificationSettings = fetcher.path(updateGlobalNotificationSettingsPath).method("patch").create();

//Engagement Notification Settings
export const getEngagementNotificationSettings = async (args: GetEngagementNotificationSettingsArgs): Promise<NotificationSettings> =>
    engagementApi(args, () => _getEngagementNotificationSettings(args));
export type NotificationSettings = components["schemas"]["NotificationSettingsResponse"];
const engagementNotificationSettingsPath = "/api/engagement/{engagementId}/notification/settings";
export type GetEngagementNotificationSettingsArgs = paths[typeof engagementNotificationSettingsPath]["get"]["parameters"]["path"];
const _getEngagementNotificationSettings = fetcher.path(engagementNotificationSettingsPath).method("get").create();

export const updateEngagementNotificationSettings = async (args: UpdateEngagementNotificationSettingArgs): Promise<NotificationSettings> => {
    const params: UpdateEngagementNotificationSettingsParams = arrayRequestBody([...args.notificationSettings], args);
    return engagementApi(args, () => _updateEngagementNotificationSettings(params));
};
export type NotificationSettingModel = components["schemas"]["NotificationSettingModel"];
export type UpdateEngagementNotificationSettingsParams = paths[typeof engagementNotificationSettingsPath]["patch"]["parameters"]["path"] &
    Array<NotificationSettingModel>;
export type UpdateEngagementNotificationSettingArgs = paths[typeof engagementNotificationSettingsPath]["patch"]["parameters"]["path"] & {
    notificationSettings: Array<NotificationSettingModel>;
};
const _updateEngagementNotificationSettings = fetcher.path(engagementNotificationSettingsPath).method("patch").create();

//Project Notification Settings
export const getProjectNotificationSettings = async (args: GetProjectNotificationSettingsArgs): Promise<NotificationSettings> =>
    projectApi(args, () => _getProjectNotificationSettings(args));
const projectNotificationSettingsPath = "/api/engagement/{engagementId}/project/{projectId}/notification/settings";
export type GetProjectNotificationSettingsArgs = paths[typeof projectNotificationSettingsPath]["get"]["parameters"]["path"];
const _getProjectNotificationSettings = fetcher.path(projectNotificationSettingsPath).method("get").create();

export const updateProjectNotificationSettings = async (args: UpdateProjectNotificationSettingArgs): Promise<NotificationSettings> => {
    const params: UpdateProjectNotificationSettingsParams = arrayRequestBody([...args.notificationSettings], args);
    return projectApi(args, () => _updateProjectNotificationSettings(params));
};
export type UpdateProjectNotificationSettingsParams = paths[typeof projectNotificationSettingsPath]["patch"]["parameters"]["path"] &
    Array<NotificationSettingModel>;
export type UpdateProjectNotificationSettingArgs = paths[typeof projectNotificationSettingsPath]["patch"]["parameters"]["path"] & {
    notificationSettings: Array<NotificationSettingModel>;
};
const _updateProjectNotificationSettings = fetcher.path(projectNotificationSettingsPath).method("patch").create();

/** START REQUEST-CONVERSATIONS */

export type ConversationRequest = components["schemas"]["ConversationRequest"];
export type ConversationResponse = components["schemas"]["RequestConversationResponse"];
export type CommentRequest = components["schemas"]["CommentRequest"];
export type CommentResponse = components["schemas"]["RequestCommentResponse"];
export type RequestCommentModel = components["schemas"]["RequestCommentModel"];

// GET engagement request conversations
const engagementRequestConversationsPath = "/api/engagement/{engagementId}/request/{requestId}/conversations";
type GetEngagementRequestConversationsArgs = paths[typeof engagementRequestConversationsPath]["get"]["parameters"]["path"];
const _getEngagementRequestConversations = fetcher.path(engagementRequestConversationsPath).method("get").create();
const getEngagementRequestConversations = async (args: GetEngagementRequestConversationsArgs): Promise<ConversationResponse[]> =>
    engagementApi(args, () => _getEngagementRequestConversations(args));

// GET project request conversations
const projectRequestConversationsPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}/conversations";
type GetProjectRrequestConversationsArgs = paths[typeof projectRequestConversationsPath]["get"]["parameters"]["path"];
const _getProjectRequestConversations = fetcher.path(projectRequestConversationsPath).method("get").create();
const getProjectRequestConversations = async (args: GetProjectRrequestConversationsArgs): Promise<ConversationResponse[]> =>
    projectApi(args, () => _getProjectRequestConversations(args));

export async function getRequestConversations(requestId: string, engagementId: string, projectId?: string) {
    if (projectId) {
        return getProjectRequestConversations({ engagementId, requestId, projectId });
    } else {
        return getEngagementRequestConversations({ engagementId, requestId });
    }
}

// POST create conversation on engagement
const engangementRequestConversationPath = "/api/engagement/{engagementId}/request/{requestId}/conversation";
type CreateNewEngagementConversationArgs = paths[typeof engangementRequestConversationPath]["post"]["parameters"]["path"] & ConversationRequest;
const _createNewEngangementConversation = fetcher.path(engangementRequestConversationPath).method("post").create();
const createNewEngangementConversation = async (args: CreateNewEngagementConversationArgs): Promise<ConversationResponse> =>
    engagementApi(args, () => _createNewEngangementConversation(args));

// POST create conversation on project
const projectRequestConversationPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}/conversation";
type CreateNewProjectConversationArgs = paths[typeof projectRequestConversationPath]["post"]["parameters"]["path"] & ConversationRequest;
const _createNewProjectConversation = fetcher.path(projectRequestConversationPath).method("post").create();
const createNewProjectConversation = async (args: CreateNewProjectConversationArgs): Promise<ConversationResponse> =>
    projectApi(args, () => _createNewProjectConversation(args));

export type CreateRequestConversationArgs = CreateNewEngagementConversationArgs & { projectId?: string };
export async function createRequestConversation(args: CreateRequestConversationArgs) {
    if (args.projectId) {
        return createNewProjectConversation(args as CreateNewProjectConversationArgs);
    } else {
        return createNewEngangementConversation(args);
    }
}

// DELETE remove conversation on engagement
const deleteEngangementRequestConversationPath = "/api/engagement/{engagementId}/conversation/{conversationId}";
type DeleteEngagementConversationArgs = paths[typeof deleteEngangementRequestConversationPath]["delete"]["parameters"]["path"];
const _deleteEngangementConversation = fetcher.path(deleteEngangementRequestConversationPath).method("delete").create();
const deleteEngangementConversation = async (args: DeleteEngagementConversationArgs): Promise<unknown> =>
    engagementApi(args, () => _deleteEngangementConversation(args));

// DELETE remove conversation on project
const deleteProjectRequestConversationPath = "/api/engagement/{engagementId}/project/{projectId}/conversation/{conversationId}";
type DeleteProjectConversationArgs = paths[typeof deleteProjectRequestConversationPath]["delete"]["parameters"]["path"];
const _deleteProjectConversation = fetcher.path(deleteProjectRequestConversationPath).method("delete").create();
const deleteProjectConversation = async (args: DeleteProjectConversationArgs): Promise<unknown> => projectApi(args, () => _deleteProjectConversation(args));

export type DeleteRequestConversationArgs = DeleteEngagementConversationArgs & { requestId: string; projectId?: string };
export async function deleteRequestConversation(args: DeleteRequestConversationArgs) {
    if (args.projectId) {
        return deleteProjectConversation(args as DeleteProjectConversationArgs);
    } else {
        return deleteEngangementConversation(args);
    }
}

// POST create conversation comment on engagement
const engangementRequestConversationCommentPath = "/api/engagement/{engagementId}/request/{requestId}/conversation/{conversationId}/comment";
type CreateNewEngagementConversationCommentArgs = paths[typeof engangementRequestConversationCommentPath]["post"]["parameters"]["path"] & CommentRequest;
const _createNewEngangementConversationComment = fetcher.path(engangementRequestConversationCommentPath).method("post").create();
const createNewEngangementConversationComment = async (args: CreateNewEngagementConversationCommentArgs): Promise<CommentResponse> =>
    engagementApi(args, () => _createNewEngangementConversationComment(args));

// POST create conversation comment on project
const projectRequestConversationCommentPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}/conversation/{conversationId}/comment";
type CreateNewProjectConversationCommentArgs = paths[typeof projectRequestConversationCommentPath]["post"]["parameters"]["path"] & CommentRequest;
const _createNewProjectConversationComment = fetcher.path(projectRequestConversationCommentPath).method("post").create();
const createNewProjectConversationComment = async (args: CreateNewProjectConversationCommentArgs): Promise<CommentResponse> =>
    projectApi(args, () => _createNewProjectConversationComment(args));

export type CreateRequestCommentArgs = CreateNewEngagementConversationCommentArgs & { projectId?: string };
export async function createRequestComment(args: CreateRequestCommentArgs) {
    if (args.projectId) {
        return createNewProjectConversationComment(args as CreateNewProjectConversationCommentArgs);
    } else {
        return createNewEngangementConversationComment(args);
    }
}

// DELETE remove conversation comment on engagement
const deleteEngangementRequestConversationCommentPath = "/api/engagement/{engagementId}/comment/{commentId}";
type DeleteEngagementConversationCommentArgs = paths[typeof deleteEngangementRequestConversationCommentPath]["delete"]["parameters"]["path"];
const _deleteEngangementConversationComment = fetcher.path(deleteEngangementRequestConversationCommentPath).method("delete").create();
const deleteEngangementConversationComment = async (args: DeleteEngagementConversationCommentArgs): Promise<unknown> =>
    engagementApi(args, () => _deleteEngangementConversationComment(args));

// DELETE remove conversation comment on project
const deleteProjectRequestConversationCommentPath = "/api/engagement/{engagementId}/project/{projectId}/comment/{commentId}";
type DeleteProjectConversationCommentArgs = paths[typeof deleteProjectRequestConversationCommentPath]["delete"]["parameters"]["path"];
const _deleteProjectConversationComment = fetcher.path(deleteProjectRequestConversationCommentPath).method("delete").create();
const deleteProjectConversationComment = async (args: DeleteProjectConversationCommentArgs): Promise<unknown> =>
    projectApi(args, () => _deleteProjectConversationComment(args));

export type DeleteRequestCommentArgs = DeleteEngagementConversationCommentArgs & { requestId: string; projectId?: string };
export async function deleteRequestComment(args: DeleteRequestCommentArgs) {
    if (args.projectId) {
        return deleteProjectConversationComment(args as DeleteProjectConversationCommentArgs);
    } else {
        return deleteEngangementConversationComment(args);
    }
}

const publishEngagementConversationPath = "/api/publish/engagement/{engagementId}/conversation/{conversationId}";
export type PublishEngagementConversationArgs = paths[typeof publishEngagementConversationPath]["put"]["parameters"]["path"];
const _publishEngagementConversation = fetcher.path(publishEngagementConversationPath).method("put").create();
export const publishEngagementConversation = async (args: PublishEngagementConversationArgs): Promise<unknown> =>
    engagementApi(args, () => _publishEngagementConversation(args));

const unpublishEngagementConversationPath = "/api/unpublish/engagement/{engagementId}/conversation/{conversationId}";
const _unpublishEngagementConversation = fetcher.path(unpublishEngagementConversationPath).method("put").create();
export const unpublishEngagementConversation = async (args: PublishEngagementConversationArgs): Promise<unknown> =>
    engagementApi(args, () => _unpublishEngagementConversation(args));

const publishProjectConversationPath = "/api/publish/engagement/{engagementId}/project/{projectId}/conversation/{conversationId}";
export type PublishProjectConversationArgs = paths[typeof publishProjectConversationPath]["put"]["parameters"]["path"];
const _publishProjectConversation = fetcher.path(publishProjectConversationPath).method("put").create();
export const publishProjectConversation = async (args: PublishProjectConversationArgs): Promise<unknown> =>
    projectApi(args, () => _publishProjectConversation(args));

const unpublishProjectConversationPath = "/api/unpublish/engagement/{engagementId}/project/{projectId}/conversation/{conversationId}";
const _unpublishProjectConversation = fetcher.path(unpublishProjectConversationPath).method("put").create();
export const unpublishProjectConversation = async (args: PublishProjectConversationArgs): Promise<unknown> =>
    projectApi(args, () => _unpublishProjectConversation(args));

export type PublishRequestConversationArgs = PublishEngagementConversationArgs & { requestId: string; projectId?: string; publish: boolean };
export async function togglePublishConversation(args: PublishRequestConversationArgs) {
    if (args.publish) {
        if (args.projectId) {
            return publishProjectConversation(args as PublishProjectConversationArgs);
        } else {
            return publishEngagementConversation(args);
        }
    } else {
        if (args.projectId) {
            return unpublishProjectConversation(args as PublishProjectConversationArgs);
        } else {
            return unpublishEngagementConversation(args);
        }
    }
}

const publishEngagementCommentPath = "/api/publish/engagement/{engagementId}/comment/{commentId}";
export type PublishEngagementCommentArgs = paths[typeof publishEngagementCommentPath]["put"]["parameters"]["path"];
const _publishEngagementComment = fetcher.path(publishEngagementCommentPath).method("put").create();
export const publishEngagementComment = async (args: PublishEngagementCommentArgs): Promise<unknown> =>
    engagementApi(args, () => _publishEngagementComment(args));

const unpublishEngagementCommentPath = "/api/unpublish/engagement/{engagementId}/comment/{commentId}";
const _unpublishEngagementComment = fetcher.path(unpublishEngagementCommentPath).method("put").create();
export const unpublishEngagementComment = async (args: PublishEngagementCommentArgs): Promise<unknown> =>
    engagementApi(args, () => _unpublishEngagementComment(args));

const publishProjectCommentPath = "/api/publish/engagement/{engagementId}/project/{projectId}/comment/{commentId}";
export type PublishProjectCommentArgs = paths[typeof publishProjectCommentPath]["put"]["parameters"]["path"];
const _publishProjectComment = fetcher.path(publishProjectCommentPath).method("put").create();
export const publishProjectComment = async (args: PublishProjectCommentArgs): Promise<unknown> => projectApi(args, () => _publishProjectComment(args));

const unpublishProjectCommentPath = "/api/unpublish/engagement/{engagementId}/project/{projectId}/comment/{commentId}";
const _unpublishProjectComment = fetcher.path(unpublishProjectCommentPath).method("put").create();
export const unpublishProjectComment = async (args: PublishProjectCommentArgs): Promise<unknown> => projectApi(args, () => _unpublishProjectComment(args));

export type PublishRequestCommentArgs = PublishEngagementCommentArgs & { requestId: string; projectId?: string; publish: boolean };
export async function togglePublishComment(args: PublishRequestCommentArgs) {
    if (args.publish) {
        if (args.projectId) {
            return publishProjectComment(args as PublishProjectCommentArgs);
        } else {
            return publishEngagementComment(args);
        }
    } else {
        if (args.projectId) {
            return unpublishProjectComment(args as PublishProjectCommentArgs);
        } else {
            return unpublishEngagementComment(args);
        }
    }
}

/** STOP REQUEST-CONVERSATIONS */
