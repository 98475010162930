import { FunctionComponent, useEffect, useMemo } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { ClientList } from "./ClientList";
import { EngagementList } from "./EngagementList";

import { Box, Card, Flex, Grid, GridItem, Icon, Link } from "@chakra-ui/react";
import { ProjectList } from "./ProjectList.tsx";
import { useClientEngagements, useClients } from "../../api";

import { useAtom } from "jotai";
import { clientIdAtom, selectedIndexAtom } from "../../atoms";
import { Page } from "../Page.tsx";
import { IconSearch, IconZoomExclamation } from "@tabler/icons-react";

export const MyEngagementsPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { clientId } = useParams();
    const [clientIdFromAtom, setClientIdAtom] = useAtom(clientIdAtom);

    useEffect(() => {
        if (!clientId && clientIdFromAtom) {
            navigate(`./client/${clientIdFromAtom}`);
        } else if (clientId && clientId !== clientIdFromAtom) {
            setClientIdAtom(clientId);
        }
    }, [clientId, clientIdFromAtom, navigate, setClientIdAtom]);

    const [selectedIndex, setSelectedIndex] = useAtom(selectedIndexAtom);
    const clientsQuery = useClients();
    const clients = useMemo(() => clientsQuery.data || [], [clientsQuery.data]);

    const engagementsQuery = useClientEngagements(clientId);

    const selectedEngagement = useMemo(
        () =>
            engagementsQuery.data && selectedIndex != undefined
                ? [...(engagementsQuery.data.engagements ?? []), ...(engagementsQuery.data.agressoEngagements ?? [])][selectedIndex]
                : undefined,
        [engagementsQuery.data, selectedIndex],
    );

    //eager fetch the lazy pages but do it after the page has loaded off the main thread
    useEffect(() => {
        const timeout = setTimeout(() => {
            import("../../pages/LazyPages.ts");
        }, 1500);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <Page title="My Engagements">
            {!clientsQuery.isLoading && clients.length === 0 ? (
                <Flex gap={"1rem"} mb={"2rem"} bg={"gray.50"} p={"1rem"} align={"center"}>
                    <Icon as={IconZoomExclamation} color={"green.500"} boxSize={"2rem"} />
                    <Box>
                        You don't have access to any clients yet. Use the{" "}
                        <Flex display={"inline-flex"} align={"baseline"}>
                            <Icon as={IconSearch} alignSelf={"center"} />
                            <Link as={RouterLink} to={"/search"} textDecoration={"underline"} px={".25rem"}>
                                Search
                            </Link>
                        </Flex>{" "}
                        page to provision, create, or request access to projects.
                    </Box>
                </Flex>
            ) : (
                <Card minWidth={"450px"} overflow={"hidden"}>
                    <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr", xl: "3fr 4fr 4fr" }}>
                        <GridItem>
                            <ClientList
                                clientId={clientId}
                                onClientSelected={clientId => {
                                    navigate(`./client/${clientId}`);
                                    setSelectedIndex(0);
                                }}
                            />
                        </GridItem>
                        <GridItem minWidth={0}>
                            <EngagementList
                                clientId={clientId}
                                selectedIndex={selectedIndex}
                                onEngagementSelected={selectedIndex => setSelectedIndex(selectedIndex)}
                            />
                        </GridItem>
                        <GridItem colSpan={{ base: 1, lg: 2, xl: 1 }} minWidth={0}>
                            <ProjectList engagement={selectedEngagement} />
                        </GridItem>
                    </Grid>
                </Card>
            )}
        </Page>
    );
};
