import { FunctionComponent } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Card, CardBody, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { useClientSearchQueryFn, UserSearchLevel } from "../../api";
import { AutoCompleteSingle } from "am-tax-fe-core";
import debounce from "lodash.debounce";
import { Page } from "../Page.tsx";

export const CreateEngagementPage: FunctionComponent = () => {
    const navigate = useNavigate();

    // ---- Setup User Search ----
    const clientSearchFn = useClientSearchQueryFn();
    const searchClients = debounce((searchText: string, level: UserSearchLevel, callback): void => {
        if (searchText.length > 0) {
            clientSearchFn({ searchText }).then(clients => {
                const results = [];
                results.push({ value: "", label: "Create Engagement Under Custom Client" });
                results.push(...clients.map(client => ({ value: client.clientId, label: `${client.clientName}` })));
                callback(results);
            });
        }
    }, 250);

    const openModal = (clientId: string | undefined) => {
        if (clientId != undefined) {
            navigate(`provisionCustomEngagement/${clientId}`);
        }
    };

    return (
        <Page
            title="Setup New Engagement"
            gutter={
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={NavLink} to="/admin">
                            Admin Dashboard
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink>Setup New Engagement</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            }
        >
            <Flex direction={"column"} gap={"1rem"}>
                <Card>
                    <CardBody bgGradient={`linear(to-br, blue.700, blue.500)`} borderRadius={"lg"}>
                        <FormControl>
                            <FormLabel color={"whiteAlpha.800"}>Client</FormLabel>
                            <FormLabel fontWeight={"400"} color={"whiteAlpha.800"}>
                                Which client will this engagement be a part of?
                            </FormLabel>
                            <AutoCompleteSingle
                                loadOptions={(searchText, callback) => searchClients(searchText, UserSearchLevel.LD, callback)}
                                placeholder="client name or project code..."
                                noOptionsMessage={obj =>
                                    obj.inputValue.length > 0 ? "No Clients Found." : "Type at least 1 character to initiate client search."
                                }
                                loadingMessage={obj =>
                                    obj.inputValue.length > 0 ? "Searching for Clients..." : "Type at least 1 character to initiate client search."
                                }
                                onChange={opt => openModal(opt ? (opt.value as string) : undefined)}
                                value={null}
                            />
                        </FormControl>
                    </CardBody>
                </Card>
            </Flex>
        </Page>
    );
};
