import { FC, MouseEvent, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import {
    Engagement,
    NavArea,
    NavDestination,
    NavItemResponse,
    Project,
    useCurrentUser,
    useEngagement,
    useNavItems,
    useNavItemsForEngagement,
    useNavItemsForProject,
    useProject,
} from "../api";
import { useAppPermissions, useEngagementAppPermissions, useProjectAppPermissions } from "../hooks";
import { ApplicationLayoutBoxShadow, getLeftNavExpandedPref, LeftNav as LeftNavCore, NavGroup, NavItem, NavItemProps, Shimmer } from "am-tax-fe-core";
import {
    IconActivity,
    IconAppWindow,
    IconBell,
    IconCalendar,
    IconDashboard,
    IconFileReport,
    IconFiles,
    IconHomeSearch,
    IconSearch,
    IconSettings,
    IconTruckLoading,
    IconUsers,
    IconUsersGroup,
} from "@tabler/icons-react";
import { Box, Flex, Heading, Icon } from "@chakra-ui/react";
import {
    ActivityArea,
    AdminArea,
    CalendarArea,
    DeliverableArea,
    DocumentArea,
    EngagementNotificationSettingArea,
    EngagementSettingArea,
    RequestArea,
    UserArea,
} from "../auth";
import { EngagmentIcon, isEngagement, ProjectIcon } from "../util/engagementUtils.ts";

function getDynamicNavItem(icons: Record<string, FC<{ size: string }>>, navItem: NavItemResponse, engagementId?: string, projectId?: string): NavItemProps {
    let to: string;
    if (navItem.destination === NavDestination.Iframe) {
        if (projectId) {
            to = `/engagement/${engagementId}/project/${projectId}/navItem/${navItem.id}`;
        } else if (engagementId) {
            to = `/engagement/${engagementId}/navItem/${navItem.id}`;
        } else {
            to = `/navItem/${navItem.id}`;
        }
    } else {
        to = navItem.href;
    }

    const Icon = IconAppWindow;

    return { to, leftIcon: <Icon size="1rem" />, text: navItem.linkText };
}

export const LeftNav: FC = () => {
    const navigate = useNavigate();
    const currentUserQuery = useCurrentUser();
    const currentUser = currentUserQuery.data;

    const matchesEngagement = useMatch("/engagement/:engagementId/*");
    const engagementIdParam = matchesEngagement?.params?.engagementId ?? "";

    const matchesProject = useMatch("/engagement/:engagementId/project/:projectId/*");
    const projectIdParam = matchesProject?.params?.projectId ?? "";

    const engagementQuery = useEngagement(engagementIdParam, !projectIdParam);
    const engagement = engagementQuery.data;
    const projectQuery = useProject(engagementIdParam, projectIdParam);
    const project = projectQuery.data;
    const projectOrEngagement: Project | Engagement | undefined = project ?? engagement;

    const navItemsQuery = useNavItems();
    const engagementNavItemsQuery = useNavItemsForEngagement(engagementIdParam, !projectIdParam);
    const projectNavItemsQuery = useNavItemsForProject(engagementIdParam, projectIdParam);

    const topItems = useMemo(() => (navItemsQuery.data ?? []).filter(item => item.area === NavArea.Top), [navItemsQuery.data]);
    const bottomItems = useMemo(() => (navItemsQuery.data ?? []).filter(item => item.area === NavArea.Bottom), [navItemsQuery.data]);
    const engagementItems = useMemo(
        () => [
            ...(navItemsQuery.data ?? []).filter(item => item.area === NavArea.Engagement),
            ...(engagementNavItemsQuery.data ?? []),
            ...(projectNavItemsQuery.data ?? []),
        ],
        [navItemsQuery.data, engagementNavItemsQuery.data, projectNavItemsQuery.data],
    );

    const icons = useMemo(() => ({}), []);
    // this is costs 2MB in page weight and no one is using it, so I'm disabling for now
    /*
        const hasDynmaicNavItems = useMemo(() => {
            return !!navItemsQuery.data?.length || !!engagementNavItemsQuery.data?.length;
        }, [navItemsQuery.data, engagementNavItemsQuery.data]);

        const [icons, setIcons] = useState<Record<string, FC<{ size: string }>>>({});
        useEffect(() => {
            if (hasDynmaicNavItems) {
                import("@tabler/icons-react").then(module => {
                    // @ts-expect-error - not worth the effort to get the types to work here
                    setIcons(module);
                });
            }
        }, [hasDynmaicNavItems]);
    */

    const engagementAppPermissions = useEngagementAppPermissions(engagementIdParam, !projectIdParam);
    const projectAppPermissions = useProjectAppPermissions(engagementIdParam, projectIdParam, !!projectIdParam);
    const projectOrEngagementPermissions = projectIdParam ? projectAppPermissions : engagementAppPermissions;
    const appPermissions = useAppPermissions();

    const items = useMemo(() => {
        const items: Array<NavGroup | NavItemProps> = [
            { to: "/myEngagements", leftIcon: <IconHomeSearch size="1rem" />, text: "My Engagements" },
            // { to: "/engagementRollup", leftIcon: <IconAssembly size="1rem" />, text: "Engagement Rollup" },
        ];

        if (currentUser?.isInternal) {
            items.push({ to: "/search", leftIcon: <IconSearch size="1rem" />, text: "Search" });
        }

        if (topItems.length) {
            for (const navItem of topItems) {
                items.push(getDynamicNavItem(icons, navItem));
            }
        }

        if (engagementIdParam || projectIdParam) {
            const groupItems = [];

            const injectedProjectPath = projectIdParam ? `/project/${projectIdParam}` : "";

            groupItems.push(
                ...[
                    {
                        to: `/engagement/${engagementIdParam}${injectedProjectPath}/dashboard`,
                        leftIcon: <IconDashboard size="1rem" />,
                        text: `${isEngagement(projectOrEngagement) ? "Engagement" : "Project"} Dashboard`,
                    },
                    {
                        show: projectOrEngagementPermissions.showArea(RequestArea),
                        isDisabled: !projectOrEngagement?.clientDocumentsRootFolderId,
                        to: `/engagement/${engagementIdParam}${injectedProjectPath}/requests`,
                        leftIcon: <IconFileReport size="1rem" />,
                        text: "Requests",
                    },
                    {
                        show: projectOrEngagementPermissions.showArea(DocumentArea),
                        isDisabled: !projectOrEngagement?.clientDocumentsRootFolderId,
                        to: `/engagement/${engagementIdParam}${injectedProjectPath}/documents`,
                        onClick: (e: MouseEvent<HTMLAnchorElement>) => {
                            e.preventDefault();
                            navigate(`/engagement/${engagementIdParam}${injectedProjectPath}/documents/${projectOrEngagement?.clientDocumentsRootFolderId}`);
                        },
                        leftIcon: <IconFiles size="1rem" />,
                        text: `${isEngagement(projectOrEngagement) ? "Engagement" : "Project"} Documents`,
                    },
                    {
                        show: projectOrEngagementPermissions.showArea(DeliverableArea),
                        isDisabled: !projectOrEngagement?.deliverablesRootFolderId,
                        to: `/engagement/${engagementIdParam}${injectedProjectPath}/deliverables`,
                        onClick: (e: MouseEvent<HTMLAnchorElement>) => {
                            e.preventDefault();
                            navigate(`/engagement/${engagementIdParam}${injectedProjectPath}/deliverables/${projectOrEngagement?.deliverablesRootFolderId}`);
                        },
                        leftIcon: <IconTruckLoading size="1rem" />,
                        text: `${isEngagement(projectOrEngagement) ? "Invoices" : "Deliverables"}`,
                    },
                    {
                        show: projectOrEngagementPermissions.showArea(CalendarArea),
                        to: `/engagement/${engagementIdParam}${injectedProjectPath}/calendar`,
                        leftIcon: <IconCalendar size="1rem" />,
                        text: "Calendar",
                    },
                    {
                        show: projectOrEngagementPermissions.showArea(ActivityArea),
                        to: `/engagement/${engagementIdParam}${injectedProjectPath}/activity`,
                        leftIcon: <IconActivity size="1rem" />,
                        text: "Activity",
                    },
                    {
                        show: projectOrEngagementPermissions.showArea(UserArea),
                        to: `/engagement/${engagementIdParam}${injectedProjectPath}/users`,
                        leftIcon: <IconUsers size="1rem" />,
                        text: "Manage Users",
                    },
                    {
                        show: projectOrEngagementPermissions.showArea(EngagementSettingArea),
                        to: `/engagement/${engagementIdParam}${injectedProjectPath}/settings`,
                        leftIcon: <IconSettings size="1rem" />,
                        text: `${isEngagement(projectOrEngagement) ? "Engagement" : "Project"} Settings`,
                    },
                    {
                        show: projectOrEngagementPermissions.showArea(EngagementNotificationSettingArea),
                        to: `/engagement/${engagementIdParam}${injectedProjectPath}/notificationsettings`,
                        leftIcon: <IconBell size="1rem" />,
                        text: `${isEngagement(projectOrEngagement) ? "Engagement" : "Project"} Notifications`,
                    },
                ],
            );

            const group: NavGroup = {
                isGroup: true,
                groupColor: isEngagement(projectOrEngagement) ? "green.500" : "blue.400",
                itemDefaults: isEngagement(projectOrEngagement)
                    ? { hoverColor: "greenAlpha.400", activeColor: "green.500" }
                    : { hoverColor: "whiteAlpha.200", activeColor: "blue.400" },
                groupHeading: (
                    <Box px={"1rem"} py={3} m={0} bg={"white"} boxShadow={ApplicationLayoutBoxShadow}>
                        {projectOrEngagement ? (
                            <Flex flexDirection={"column"} alignItems={"center"} gap={"7px"}>
                                <Heading variant={"info"} size={"sm"} maxWidth={"100%"} textAlign={"center"}>
                                    {projectOrEngagement?.clientName}
                                </Heading>

                                <Flex gap={"3px"} maxWidth={"100%"} alignItems={"center"}>
                                    <Heading variant={"info"}>
                                        {isEngagement(projectOrEngagement) ? (
                                            <Icon as={EngagmentIcon} boxSize={"1.5rem"} />
                                        ) : (
                                            <Icon as={ProjectIcon} boxSize={"1.5rem"} />
                                        )}
                                    </Heading>
                                    <Heading variant={"info"} size={"sm"}>
                                        {projectOrEngagement?.projectCode}
                                    </Heading>
                                </Flex>
                            </Flex>
                        ) : (
                            <Shimmer />
                        )}
                    </Box>
                ),
                items: groupItems,
            };

            if (engagementItems.length) {
                for (const navItem of engagementItems) {
                    group.items.push(getDynamicNavItem(icons, navItem, engagementIdParam));
                }
            }

            items.push(group);
        }

        if (bottomItems.length) {
            for (const navItem of bottomItems) {
                items.push(getDynamicNavItem(icons, navItem));
            }
        }
        return items;
    }, [
        currentUser?.isInternal,
        topItems,
        engagementIdParam,
        projectIdParam,
        bottomItems,
        icons,
        projectOrEngagement,
        projectOrEngagementPermissions,
        engagementItems,
        navigate,
    ]);

    const [expanded, setExpandedState] = useState(getLeftNavExpandedPref());

    const itemsFooter = useMemo(() => {
        if (appPermissions.has(AdminArea.update)) {
            return (
                <Box>
                    <NavItem
                        show={appPermissions.has(AdminArea.update)}
                        to={"/admin"}
                        leftIcon={<IconUsersGroup />}
                        text={expanded ? "Admin Dashboard" : ""}
                        itemKey={"admin"}
                        isExpanded={expanded}
                        activeColor={"aqua.500"}
                    />
                </Box>
            );
        }
    }, [appPermissions, expanded]);

    return <LeftNavCore logo={{ type: "global" }} appName={"Engage"} items={items} itemsFooter={itemsFooter} onSizeChange={setExpandedState} />;
};
