import { useProjectAreaPermissions } from "../../hooks";
import { RequestArea } from "../../auth";
import {
    DeleteProjectRequestArgs,
    invalidateDocumentCacheForProjectAndFolder,
    invalidateProjectRequestQueries,
    PostProjectRequestArgs,
    PutProjectRequestArgs,
    Request,
    UploadDocumentsToProjectRequestArgs,
    useDeleteProjectRequest,
    useProject,
    useProjectRequest,
    useProjectUsers,
    useSaveProjectRequest,
    useUploadDocumentToProjectRequest,
} from "../../api";
import { Mutation } from "../../util/queryUtils.ts";
import {
    DeleteRequestArgs,
    ProjectEngagement,
    RequestFormProps,
    SaveRequestArgs,
    UploadDocumentsToRequestArgs,
} from "../EngagementRequestPage/RequestForm.tsx";
import { useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";

export function useRequestFormForProject(engagementId: string, projectId: string, requestId: string): Omit<RequestFormProps, "navigateBack"> {
    const areaPermissions = useProjectAreaPermissions(RequestArea, engagementId, projectId);
    const projectEngagementQuery = useProject(engagementId, projectId);
    const deleteRequestQuery = useDeleteProjectRequest();
    const requestQuery = useProjectRequest(engagementId, projectId, requestId, !deleteRequestQuery.isPending && !deleteRequestQuery.isSuccess);
    const participantsQuery = useProjectUsers(engagementId, projectId);
    const saveRequestQuery = useSaveProjectRequest(true);
    const uploadFileToRequestQuery = useUploadDocumentToProjectRequest(true);

    const saveRequestMutation = useMemo(() => {
        class SaveMutation extends Mutation<PostProjectRequestArgs | PutProjectRequestArgs> {
            updateArgs(args: PostProjectRequestArgs | PutProjectRequestArgs) {
                return {
                    ...args,
                    projectId,
                };
            }
        }

        return new SaveMutation(saveRequestQuery);
    }, [projectId, saveRequestQuery]);

    const deleteRequestMutation = useMemo(() => {
        class DeleteMutation extends Mutation<DeleteProjectRequestArgs> {
            updateArgs(args: DeleteProjectRequestArgs) {
                return {
                    ...args,
                    projectId,
                };
            }
        }

        return new DeleteMutation(deleteRequestQuery) as Mutation<DeleteProjectRequestArgs>;
    }, [projectId, deleteRequestQuery]);

    const uploadFileToRequestMutation = useMemo(() => {
        class UploadMutation extends Mutation<UploadDocumentsToProjectRequestArgs> {
            updateArgs(args: UploadDocumentsToProjectRequestArgs) {
                return {
                    ...args,
                    projectId,
                };
            }
        }

        return new UploadMutation(uploadFileToRequestQuery) as Mutation<UploadDocumentsToProjectRequestArgs>;
    }, [projectId, uploadFileToRequestQuery]);

    const queryClient = useQueryClient();
    const onSaveComplete = (projectOrEngagement: ProjectEngagement, request: Request, filesUploaded?: boolean) => {
        invalidateProjectRequestQueries(queryClient, request.engagementId, request.projectId!, request.requestId);
        if (filesUploaded) {
            invalidateDocumentCacheForProjectAndFolder(queryClient, request.engagementId, request.projectId!, projectOrEngagement.clientDocumentsRootFolderId);
        }
    };

    return {
        areaPermissions,
        projectEngagementQuery,
        requestQuery,
        participantsQuery,
        saveRequestMutation: saveRequestMutation as Mutation<SaveRequestArgs, Request>,
        deleteRequestMutation: deleteRequestMutation as Mutation<DeleteRequestArgs>,
        uploadFileToRequestMutation: uploadFileToRequestMutation as Mutation<UploadDocumentsToRequestArgs>,
        onSaveComplete,
    };
}
