import { PermissionsByRole } from "../api";
import { allAreas, Permission, PermissionsByAreaType, SecurityArea, AreaPermissions } from "./Permissions.ts";

function getPermissionsByAreaName() {
    const permissionsByAreaName: Record<string, Set<Permission>> = {};
    for (const area of allAreas) {
        permissionsByAreaName[area._name] = generatePermissionSet(area);
    }
    return permissionsByAreaName;
}
function generatePermissionSet(area: SecurityArea): Set<Permission> {
    return new Set(
        Object.entries(area)
            .filter(([key]) => key !== "_name")
            .map(([, value]) => value),
    );
}
const permissionsByAreaName = getPermissionsByAreaName();

function getPermissionSet(area: SecurityArea): Set<string> {
    return permissionsByAreaName[area._name];
}

export function roleCanSeeArea(permissionsByRole: PermissionsByRole, area: SecurityArea, roleIds: Array<number | undefined | null>) {
    const areaPermissions = getPermissionSet(area);
    for (const roleId of roleIds) {
        if (roleId) {
            for (const perm of permissionsByRole[roleId] || []) {
                if (areaPermissions.has(perm)) {
                    return true;
                }
            }
        }
    }
    return false;
}

export function roleHasPermission(permissionsByRole: PermissionsByRole, permission: Permission | null, roleIds: Array<number | undefined | null>) {
    if (permission) {
        return roleHasAnyPermission(permissionsByRole, [permission], roleIds);
    } else {
        return false;
    }
}

export function roleHasAnyPermission(permissionsByRole: PermissionsByRole, permissions: Permission[], roleIds: Array<number | undefined | null>) {
    for (const roleId of roleIds) {
        if (roleId) {
            for (const permission of permissions) {
                if (permissionsByRole[roleId]?.has(permission)) {
                    return true;
                }
            }
        }
    }
    return false;
}

export function canCreateOrUpdate(
    areaPermissions: AreaPermissions<SecurityArea>,
    createPermission: PermissionsByAreaType<SecurityArea> | null,
    updatePermission: PermissionsByAreaType<SecurityArea> | null,
    entityId: string | false | undefined | null,
): boolean {
    if (entityId) {
        return areaPermissions.has(updatePermission);
    } else {
        return areaPermissions.has(createPermission);
    }
}

export function canDelete(
    areaPermissions: AreaPermissions<SecurityArea>,
    deletePermission: PermissionsByAreaType<SecurityArea>,
    entityId: string | false | undefined | null,
): boolean {
    if (entityId) {
        return areaPermissions.has(deletePermission);
    } else {
        return false;
    }
}

/**
 * Use this function to check if the user has the permission to perform the action they are trying to perform when accessing an add/edit/delete page.
 * @param areaPermissions
 * @param createPermission
 * @param readPermission
 * @param updatePermission
 * @param deletePermission
 * @param entityId
 */
export function accessMatchesIntent(
    areaPermissions: AreaPermissions<SecurityArea>,
    createPermission: PermissionsByAreaType<SecurityArea> | null,
    readPermission: PermissionsByAreaType<SecurityArea> | null,
    updatePermission: PermissionsByAreaType<SecurityArea> | null,
    deletePermission: PermissionsByAreaType<SecurityArea> | null,
    entityId: string | undefined | null,
): boolean {
    if (entityId) {
        return areaPermissions.has(readPermission) || areaPermissions.has(updatePermission) || areaPermissions.has(deletePermission);
    } else {
        return areaPermissions.has(createPermission);
    }
}
