import { FC, lazy, useMemo } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { EngagementRollupPage } from "./EngagementRollupPage";
import { MyEngagementsPage } from "./MyEngagementsPage";
import { UnauthorizedPage } from "./UnauthorizedPage";
import { NotFoundPage } from "./NotFoundPage";
import { registerGlobal403Handler, withOutlet } from "am-tax-fe-core";
import {
    ActivityArea,
    AdminArea,
    CalendarArea,
    DeliverableArea,
    DocumentArea,
    EngagementArea,
    EngagementNotificationSettingArea,
    EngagementSettingArea,
    ProjectArea,
    RequestArea,
    UserArea,
} from "../auth";
import { appGuard, appGuardWithOutlet, engagementGuard, engagementGuardWithOutlet, projectGuard, projectGuardWithOutlet } from "../util/reactUtils.tsx";
import ApplicationLayout from "./ApplicationLayout.tsx";
import { EngagementRouteGuard } from "../components/EngagementRouteGuard";
import { ProjectRouteGuard } from "../components/ProjectRouteGuard";
import { EngagementRequestPage } from "./EngagementRequestPage";
import { EngagementEventModal, EngagementEventPage } from "./EngagementEventPage";
import { ProjectRequestPage } from "./ProjectRequestPage";
import { ProjectEventModal, ProjectEventPage } from "./ProjectEventPage";
import { CreateEngagementPage } from "./CreateEngagementPage";

// Don't load these pages until they are needed
const AppProvisioningPage = lazy(() => import("./LazyPages").then(module => ({ default: module.AppProvisioningPage })));
const EngagementBoxPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementBoxPage })));
const ProjectBoxPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectBoxPage })));
const ProfilePage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProfilePage })));
const ProjectSearchPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectSearchPage })));
const CreateProjectPage = lazy(() => import("./LazyPages").then(module => ({ default: module.CreateProjectPage })));
const ProvisionEngagementModal = lazy(() => import("./LazyPages").then(module => ({ default: module.ProvisionEngagementModal })));
const ProvisionProjectModal = lazy(() => import("./LazyPages").then(module => ({ default: module.ProvisionProjectModal })));
const EngagementDashboardPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementDashboardPage })));
const ProjectDashboardPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectDashboardPage })));
const EngagementRequestsPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementRequestsPage })));
const ProjectRequestsPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectRequestsPage })));
const EngagementSettingsPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementSettingsPage })));
const ProjectSettingsPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectSettingsPage })));
const ProvisionCustomEngagementModal = lazy(() => import("./LazyPages").then(module => ({ default: module.ProvisionCustomEngagementModal })));
const ProvisionCustomProjectModal = lazy(() => import("./LazyPages").then(module => ({ default: module.ProvisionCustomProjectModal })));
const EngagementDocumentsPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementDocumentsPage })));
const ProjectDocumentsPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectDocumentsPage })));
const EngagementDocumentPreviewPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementDocumentPreviewPage })));
const ProjectDocumentPreviewPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectDocumentPreviewPage })));
const EngagementDeliverablesPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementDeliverablesPage })));
const ProjectDeliverablesPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectDeliverablesPage })));
const EngagementCalendarPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementCalendarPage })));
const ProjectCalendarPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectCalendarPage })));
const EngagementActivitySearchPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementActivitySearchPage })));
const ProjectActivitySearchPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectActivitySearchPage })));
const EngagementUsersPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementUsersPage })));
const ProjectUsersPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectUsersPage })));
const DynamicNavIFramePage = lazy(() => import("./LazyPages").then(module => ({ default: module.DynamicNavIFramePage })));
const EngagementNotificationSettingsPage = lazy(() => import("./LazyPages").then(module => ({ default: module.EngagementNotificationSettingsPage })));
const ProjectNotificationSettingsPage = lazy(() => import("./LazyPages").then(module => ({ default: module.ProjectNotificationSettingsPage })));
const AdminDashboardPage = lazy(() => import("./LazyPages").then(module => ({ default: module.AdminDashboardPage })));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<ApplicationLayout />}>
            <Route index element={<Navigate to="myEngagements" replace />} />
            <Route path="engagementRollup" element={<EngagementRollupPage />} />
            <Route path="myEngagements" {...withOutlet(<MyEngagementsPage />)}>
                <Route path="client/:clientId">
                    <Route path="provisionEngagement/:projectCode/:engagementName" element={<ProvisionEngagementModal />} />
                    <Route path="provisionCustomEngagement" element={<ProvisionCustomEngagementModal />} />
                    <Route path="provisionProject/:engagementId/:projectCode/:projectName" element={<ProvisionProjectModal />} />
                    <Route path="provisionCustomProject/:engagementId" element={<ProvisionCustomProjectModal />} />
                </Route>
            </Route>
            <Route path="search" {...withOutlet(<ProjectSearchPage />)}>
                <Route path="provisionProject/:clientId/:engagementId/:projectCode/:projectName" element={<ProvisionProjectModal />} />
                <Route path="provisionEngagement/:clientId/:projectCode/:engagementName" element={<ProvisionEngagementModal />} />
            </Route>
            <Route path="search/create" {...appGuardWithOutlet([ProjectArea.create], <CreateProjectPage />)}>
                <Route path="client/:clientId">
                    <Route path="provisionProject/:engagementId/:projectCode/:projectName" element={<ProvisionProjectModal />} />
                    <Route path="provisionCustomProject/:engagementId" element={<ProvisionCustomProjectModal />} />
                </Route>
            </Route>
            <Route path="admin">
                <Route path="" {...appGuard([AdminArea.update], <AdminDashboardPage />)} />
                <Route path="createEngagement" {...appGuardWithOutlet([EngagementArea.create], <CreateEngagementPage />)}>
                    <Route path="provisionEngagement/:clientId/:projectCode/:engagementName" element={<ProvisionEngagementModal />} />
                    <Route path="provisionCustomEngagement/:clientId?" element={<ProvisionCustomEngagementModal />} />
                </Route>
            </Route>
            <Route path="navItem/:navItemId" element={<DynamicNavIFramePage />} />

            {/*Engagement Routes*/}
            <Route path="engagement/:engagementId">
                <Route path="dashboard" {...withOutlet(<EngagementDashboardPage />)}>
                    <Route path="event/:eventId/:startDate?" {...engagementGuard(CalendarArea, <EngagementEventModal />)} />
                </Route>
                <Route path="requests/request/:requestId?" {...engagementGuard(RequestArea, <EngagementRequestPage />)} />
                <Route path="requests" {...engagementGuardWithOutlet(RequestArea, <EngagementRequestsPage />)} />
                <Route path="request/:requestId/:dueDate?" {...engagementGuard(RequestArea, <EngagementRequestPage />)} />
                <Route path="documents/:folderId" {...engagementGuard(DocumentArea, <EngagementDocumentsPage />)} />
                <Route path="documents/:documentId/box" {...engagementGuard(DocumentArea, <EngagementBoxPage />)} />
                <Route path="documents/:documentId/preview" {...engagementGuard(DocumentArea, <EngagementDocumentPreviewPage />)} />
                <Route path="deliverables/:folderId" {...engagementGuard(DeliverableArea, <EngagementDeliverablesPage />)} />
                <Route path="deliverables/:documentId/box" {...engagementGuard(DeliverableArea, <EngagementBoxPage />)} />
                <Route path="deliverables/:documentId/preview" {...engagementGuard(DeliverableArea, <EngagementDocumentPreviewPage />)} />
                <Route path="calendar/request/:requestId/:dueDate?" {...engagementGuard(RequestArea, <EngagementRequestPage />)} />
                <Route path="calendar" {...engagementGuardWithOutlet(CalendarArea, <EngagementCalendarPage />)}>
                    <Route path="event/:eventId/:startDate?" {...engagementGuard(CalendarArea, <EngagementEventModal />)} />
                </Route>
                <Route
                    element={<EngagementRouteGuard hasAny={[CalendarArea.create, CalendarArea.update, CalendarArea.delete]} render={<EngagementEventPage />} />}
                />
                <Route path="activity" {...engagementGuard(ActivityArea, <EngagementActivitySearchPage />)} />
                <Route path="users" {...engagementGuard(UserArea, <EngagementUsersPage />)} />
                <Route path="navItem/:navItemId" element={<DynamicNavIFramePage />} />
                <Route path="settings" {...engagementGuard(EngagementSettingArea, <EngagementSettingsPage />)} />
                <Route path="notificationsettings" {...engagementGuard(EngagementNotificationSettingArea, <EngagementNotificationSettingsPage />)} />

                {/*Project Routes*/}
                <Route path="project/:projectId">
                    <Route path="dashboard" {...withOutlet(<ProjectDashboardPage />)}>
                        <Route path="event/:eventId/:startDate?" {...projectGuard(CalendarArea, <ProjectEventModal />)} />
                    </Route>
                    <Route path="requests/request/:requestId?" {...projectGuard(RequestArea, <ProjectRequestPage />)} />
                    <Route path="requests" {...projectGuardWithOutlet(RequestArea, <ProjectRequestsPage />)} />
                    <Route path="request/:requestId/:dueDate?" {...projectGuard(RequestArea, <ProjectRequestPage />)} />
                    <Route path="documents/:folderId" {...projectGuard(DocumentArea, <ProjectDocumentsPage />)} />
                    <Route path="documents/:documentId/box" {...projectGuard(DocumentArea, <ProjectBoxPage />)} />
                    <Route path="documents/:documentId/preview" {...projectGuard(DocumentArea, <ProjectDocumentPreviewPage />)} />
                    <Route path="deliverables/:folderId" {...projectGuard(DeliverableArea, <ProjectDeliverablesPage />)} />
                    <Route path="deliverables/:documentId/box" {...projectGuard(DeliverableArea, <ProjectBoxPage />)} />
                    <Route path="deliverables/:documentId/preview" {...projectGuard(DeliverableArea, <ProjectDocumentPreviewPage />)} />
                    <Route path="calendar/request/:requestId/:dueDate?" {...projectGuard(RequestArea, <ProjectRequestPage />)} />
                    <Route path="calendar" {...projectGuardWithOutlet(CalendarArea, <ProjectCalendarPage />)}>
                        <Route path="event/:eventId/:startDate?" {...projectGuard(CalendarArea, <ProjectEventModal />)} />
                    </Route>
                    <Route
                        path="event/:eventId"
                        element={<ProjectRouteGuard hasAny={[CalendarArea.create, CalendarArea.update, CalendarArea.delete]} render={<ProjectEventPage />} />}
                    />
                    <Route path="activity" {...projectGuard(ActivityArea, <ProjectActivitySearchPage />)} />
                    <Route path="users" {...projectGuard(UserArea, <ProjectUsersPage />)} />
                    <Route path="navItem/:navItemId" element={<DynamicNavIFramePage />} />
                    <Route path="settings" {...projectGuard(EngagementSettingArea, <ProjectSettingsPage />)} />
                    <Route path="notificationsettings" {...projectGuard(EngagementNotificationSettingArea, <ProjectNotificationSettingsPage />)} />
                </Route>
            </Route>
            <Route path="profile" element={<ProfilePage />} />
            <Route path="appProvisioning" element={<AppProvisioningPage />} />
            <Route path="unauthorized" element={<UnauthorizedPage />} />
            <Route path="404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Route>,
    ),
);

export const PageRoutes: FC = () => {
    useMemo(() => {
        registerGlobal403Handler(() => {
            router.navigate("/unauthorized");
        });
    }, []);

    return <RouterProvider router={router} />;
};
