import { AMTaxLogoGlobalWhite, LoginPage, MsalLoginForm } from "am-tax-fe-core";
import BgImage from "../../assets/images/8C0862F6-1A5B-4941-8C67-874FCFAD2E05.jpg";

export const UnauthenticatedLandingPage = () => {
    return (
        <LoginPage
            bgImage={`url(${BgImage})`}
            appName={import.meta.env.VITE_APP_NAME}
            logoSvg={AMTaxLogoGlobalWhite}
            footerItems={{ items: [], replace: true }}
        >
            <MsalLoginForm />
        </LoginPage>
    );
};
